import { Button } from "@mui/material";
import React from "react";
import googleplay from './images/googleplay.png';
import appstore from './images/appstore.png';
import anqlogo from './images/Anqlogo.png';

const Prepaidcard=()=>{
    return(
        <>
        <div className="container-fluid" style={{fontFamily:"Satoshi"}}>
            <div className="row p-5">
                <div className="col-12 mt-4 text-center">
                    <img src={anqlogo} alt="ANQ logo" className="img-fluid p-2 rounded" style={{backgroundColor:"black",width:"auto",height:"180px"}}/>
                    {/* <h4><u>ANQ APP</u></h4> */}
                </div>
                <div className="col-12 p-5">
                    <h3>About the Product</h3>
                    <p>
                    1. ANQ is a digital Banking platform where its users can get rewards on daily spend in Gold. ( You can sell the gold on the app and withdraw the rewards into any bank account )
                    </p>
                    <p>
                    2. ANQ has a prepaid card called X card where you users get 1% rewards on every transaction.
                    </p>
                    <p>
                    3. Shopping where the users get 1 - 16% rewards on the purchase of the vouchers of swiggy, Zomato, Amazon  and 200+  brands.
                    </p>
                    <p>
                    4.  Recently launched Dual Credit Card in partnership with YesBank
                    </p>
                    <p>
                    5. Eligible users can apply for credit cards and once approved, the users will get one Rupay Credit card which they can link with UPI and can use for UPI payments and one Master Card which the user can use for POS transactions and International Transactions.
                    </p>
                    <p>
                    6. In Right side corner there is mint option if we click on it for every 24hrs you can earn upto 1 milligram gold 
                    </p>
                    <p>
                    7. Every day you can earn  extra benefits for your daily spends by 200+ brands                    </p>
                    <p>
                    8. Credit Card holders will get complimentary International and domestic Lounge access at the airports.
                    </p>
                    <p>
                    9. Best in class rewards on spends across different categories.
                    </p>
                    <p>
                    10. No annual and Maintenance fee on the ANQ Platform
                    </p>
                    <p>
                    11. Click on the link to get register with ANQ and get the Prepaid Card
                    </p>
                    <p>
                    12. You can also download the ANQ app from Play Store and App store.
                    </p>
                </div>
                <div className="col-12 d-flex justify-content-end">
                    <a href="https://anqapp.sng.link/Avakj/psj1/bq4s" className="p-2">
                        <img src={googleplay} className="img-fluid"/>
                    </a>
                    <a href="https://anqapp.sng.link/Avakj/psj1/bq4s" className="p-2">
                        <img src={appstore} className="img-fluid" />
                    </a>
                </div>
            </div>
        </div>
        
        </>
    )
}

export default Prepaidcard;