import React from "react";
import './App.css';
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Cardcategory from "./Cardcategories";
// images
import AU from './images/card/AU/1.webp';
import Axis from './images/card/axis/1.png';
import HDFC from './images/card/hdfc/1.png';
import Shoppers from './images/card/hdfc/2.png';
import Tata from './images/card/hdfc/3.png';
import Swiggy from './images/card/hdfc/swiggy.png';
import HPCL from './images/card/idfc/HPCL.png';
import Swyp from './images/card/idfc/swyp.jpeg';
import Idfcfirst from './images/card/idfc/IDFCFIRST.webp';
import Induslandplatinum from './images/card/indus bank/PlatinumCard.png';
import Induslandcredit from './images/card/indus bank/InduslandCreditcard.png';
import Cashbacksbi from './images/card/sbi/cashback.png';
import Irctcsbi from './images/card/sbi/irctcsbi.png';
import Simplysavesbi from './images/card/sbi/simplysave.png';
import Simplyclicksbi from './images/card/sbi/simplyclick.png';
import Icici from './images/card/icici/icici.png';
import AI from './images/ICONS/AI POWERED.png';
import Artboard from './images/ICONS/Artboard.png';
import Comparision from './images/ICONS/COMPARISONS.png';
import Options from './images/ICONS/OPTIONS.png';
import gifBanner from './images/banner.gif';
import bg from './images/bg.png';
// images
import Banner from './images/banner.mp4';
import { Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';


function App() {

  const [showBenefits, setShowBenefits] = React.useState(false);
  const [axiscardbenefits, setAxiscardBenefits] = React.useState(false);
  const [hdfcstoppersbenefits, setHdfcstoppersbenefits] = React.useState(false);
  const [hdfccreditbenefits, sethdfccreditBenefits] = React.useState(false);
  const [cardstoggle, setCardstoggle] = React.useState(false);
  const [icicicreditbenefits, setIcicicreditbenefits] = React.useState(false);
  const [idfcbankcreditbenefits, setIdfcbankcreditbenefits] = React.useState(false);
  const [idfcfirstswypbenefits, setIdfcswpcardbenefits] = React.useState(false);
  const [idfcfirsthpclbenefits, setIdfcfirsthpclbenefits] = React.useState(false);
  const [induslandplatinumbenefits, setInduslandplatinumbenefits] = React.useState(false);
  const [induslandcreditcardbenefits, setInduslandcreditcardbenefits] = React.useState(false);
  const [cashbacksbibenefits, setCashbacksbibenefits] = React.useState(false);
  const [simplyclicksbibenefits, setSimplyclicksbibenefits] = React.useState(false);
  const [irctcsbiplatinumbenefits, setIrctcsbiplatinumbenefits] = React.useState(false);
  const [simplysavesbibenefits, setSimplysavesbibenefits] = React.useState(false);
  const [swiggyhdfcbenefits, setSwiggyhdfcbenefits] = React.useState(false);
  const [tataneuhdfcbenefits, setTataneuhdfcbenefits] = React.useState(false);

  const Irctcplatinum = () => {
    setIrctcsbiplatinumbenefits((prev) => !prev);
  }

  const SimplysaveSbi = () => {
    setSimplysavesbibenefits((prev) => !prev);
  }

  const Swiggyhdfc = () => {
    setSwiggyhdfcbenefits((prev) => !prev);
  }

  const Tataneuhdfc = () => {
    setTataneuhdfcbenefits((prev) => !prev);
  }

  const Induslandplatinumcardbenefits = () => {
    setInduslandplatinumbenefits((prev) => !prev);
  }

  const InduslandcreditBenefits = () => {
    setInduslandcreditcardbenefits((prev) => !prev);
  }

  const CashbacksbiBenefits = () => {
    setCashbacksbibenefits((prev) => !prev);
  }

  const SimplyclicksbiBenefits = () => {
    setSimplyclicksbibenefits((prev) => !prev);
  }

  const IcicicardBenefits = () => {
    setIcicicreditbenefits((prev) => !prev);
  }

  const Idfcbankcardbenefits = () => {
    setIdfcbankcreditbenefits((prev) => !prev);
  }

  const Idfcfirstswypcardbenefits = () => {
    setIdfcswpcardbenefits((prev) => !prev);
  }

  const Idfcfirsthpclcardbenefits = () => {
    setIdfcfirsthpclbenefits((prev) => !prev)
  }


  const toggleBenefits = () => {
    setShowBenefits((prev) => !prev);
  };

  const AxiscardBenefits = () => {
    setAxiscardBenefits((prev) => !prev);
  };

  const hdfcstoppersBenefits = () => {
    setHdfcstoppersbenefits((prev) => !prev)
  }

  const HDFCcreditbenefits = () => {
    sethdfccreditBenefits((prev) => !prev)
  }

  const Remainingcardstoggle = () => {
    setCardstoggle((prev) => !prev)
  }


  return (
    <>
      {/* Banner */}

      <video
        style={{ width: "100%", height: "400px", objectFit: "contain" }}
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={Banner} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {/* Banner */}


      {/* services */}
      <div className="container-fluid" style={{ fontFamily: "Satoshi", backgroundColor: "#CACDD1" }} >
        <div className="row p-5">
          <div className="col-12" id="about">
            <h3 className="text-center mb-4" style={{ fontFamily: "Azonix" }}>Why Choose Select Cards?</h3>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
            <Card style={{ borderRadius: "20px", height: "333.8px",marginTop:"20px" }}>
              <CardContent>
                <div className="text-center">
                  <img src={Artboard} style={{ width: "auto", height: "123.56px" }} className="img-fluid" />
                </div>
                <p className="text-center fw-bold" style={{ fontSize: "16.71px" }}>Expert Insights</p>
                <p className="text-center text-secondary" style={{ fontSize: "14.71px" }}>Our platform ensures you fully understand the benefits and features of each card, so there’s no guesswork involved.
                </p>
              </CardContent>
            </Card>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
            <Card style={{ borderRadius: "20px", height: "333.8px",marginTop:"20px" }}>
              <CardContent>
                <div className="text-center">
                  <img src={AI} className="img-fluid" style={{ width: "auto", height: "123.56px" }} alt="AI icon" />
                </div>
                <p className="text-center fw-bold" style={{ fontSize: "16.71px" }}>AI-Powered Personalization</p>
                <p className="text-center text-secondary" style={{ fontSize: "14.71px" }}>Our advanced AI technology evaluates your financial behavior, spending patterns, and goals to recommend cards tailored just for you.
                </p>
              </CardContent>
            </Card>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
            <Card style={{ borderRadius: "20px", height: "333.8px",marginTop:"20px" }}>
              <CardContent>
                <div className="text-center">
                  <img src={Comparision} className="img-fluid" style={{ width: "auto", height: "123.56px" }} alt="comparision" />
                </div>
                <p className="text-center fw-bold" style={{ fontSize: "16.71px" }}>Clear & Transparent Comparisons</p>
                <p className="text-center text-secondary" style={{ fontSize: "14.71px" }}>We break down rewards programs, annual fees, and other details to help you make an informed decision.
                </p>
              </CardContent>
            </Card>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3" >
            <Card style={{ borderRadius: "20px", height: "333.8px",marginTop:"20px" }}>
              <CardContent>
                <div className="text-center">
                  <img src={Options} className="img-fluid" style={{ width: "auto", height: "123.56px" }} alt="card" />
                </div>
                <p className="text-center fw-bold" style={{ fontSize: "16.71px" }}>Comprehensive Card Options</p>
                <p className="text-center text-secondary" style={{ fontSize: "14.71px" }}>Whether it’s travel rewards, cashback, low-interest rates, or credit-building, we provide a wide variety of choices to suit any financial goal.
                </p>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
      {/* services */}

      {/* submitform */}
      <div className="container-fluid pb-5" style={{ backgroundColor: "#CACDD1" }}>
        <div className="container" style={{ fontFamily: "Satoshi" }}>
          <div className="row p-3" style={{ backgroundColor: "#ECECEC", borderRadius: "20px" }}>
            <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
              <h4 className="formtext fw-bold">To Get You the Right Card Submit Your Details</h4>
            </div>
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 text-center">
              <Link to='/submit' type="button" style={{textDecoration:"none"}}><Button className="ps-5 pe-5 pt-2 pb-2 fs-5 text-white text-center fw-bold" style={{ backgroundColor: "#1976D3",borderRadius:"10px" }}>CLICK HERE</Button></Link>
            </div>
          </div>
        </div>
      </div>
      {/* submitform */}

      {/* Card Categories */}
      {/* <Cardcategory/> */}
      {/* Card Categories */}



      {/* Card display */}
      <div className="container-fluid" style={{ fontFamily: "Satoshi", backgroundImage: `url(${bg})` }}>
        <div className="row p-5">
          <div className="col-12 pb-5" id="category">
            <h3 className="text-center text-white" style={{ fontFamily: "Azonix" }}>Card Collections</h3>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
            <Card sx={{ maxWidth: 345 }} className="mt-3 mx-auto" style={{
              borderRadius: "20px",
              background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
              backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
              border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
              color: "white"
            }}>
              {/* <CardMedia
                style={{ height: 180 }}
                image="https://www.axisbank.com/images/default-source/revamp_new/cards/cc-new/atlas-credit-card-facia.jpg"
                title="Axis credit card"
              /> */}
              <CardContent>
                <div className="text-center">
                  <img src={AU} className="img-fluid" onClick={toggleBenefits} style={{ height: "150px", cursor: "pointer" }} title="Axis Credit Card" />
                </div>
                <p gutterBottom className="pt-3" component="div" style={{fontSize:"15px"}}>
                  AU small Finance Bank Credit Card
                </p>

                <div className="d-flex flex-md-row flex-column justify-content-around">
                  <Button variant="outlined" color="inherit" onClick={toggleBenefits} sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>
                    {showBenefits ? "Hide Benefits" : "Show Benefits"}
                  </Button>
                  <a href="https://indiasales.club/TBKH0476/HA2N2699/AK" className="btn-container" style={{ textDecoration: "none" }} target="_blank"><Button variant="contained" className="btn-responsive" sx={{ mt: 2, fontFamily: "Satoshi"}} style={{ textTransform: "none" }}>Apply Now</Button></a>
                </div>
                {showBenefits && (
                  <div>
                    <p style={{ fontWeight: 'Bold', marginTop: '10px' }}>
                      Product Benefits:
                    </p>
                    <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>

                      <ArrowRightIcon /> No physical documents required
                    </p>
                    <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>

                      <ArrowRightIcon /> 25% discount on movie tickets*
                    </p>
                    <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>

                      <ArrowRightIcon /> Lifetime Free Credit Cards (selected variants)
                    </p>
                    <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>

                      <ArrowRightIcon /> Instant approval
                    </p>
                    <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>

                      <ArrowRightIcon /> Complimentary Airport, Railway Lounge Access
                    </p>
                    <p
                      variant="body2"
                      style={{ color: "text.secondary", fontSize: "12px", paddingTop: "5px" }}
                    >

                      <ArrowRightIcon /> Discounts on Zomato, Swiggy and online shopping*
                    </p>
                  </div>
                )}
              </CardContent>

            </Card>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
            <Card sx={{ maxWidth: 345 }} className="mt-3 mx-auto" style={{
              borderRadius: "20px",
              background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
              backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
              border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
              color: "white"
            }}>

              <CardContent>
                <div className="text-center">
                  <img src={Axis} className="img-fluid" style={{ height: "150px" }} title="Axis Credit Card" />
                </div>
                <p gutterBottom className="pt-3" component="div" style={{fontSize:"15px"}}>
                  Axis Bank Credit Card
                </p>

                <div className="d-flex flex-md-row flex-column justify-content-around">
                  <Button variant="outlined" color="inherit" onClick={AxiscardBenefits} sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>
                    {axiscardbenefits ? "Hide Benefits" : "Show Benefits"}
                  </Button>
                  <a href="https://indiasales.club/TBKH0476/HA2N2699/ACK" className="btn-container" style={{ textDecoration: "none" }} target="_blank"><Button variant="contained" className="btn-responsive" sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>Apply Now</Button></a>
                </div>
                {axiscardbenefits && (
                  <div>
                    <p style={{ fontWeight: 'Bold', marginTop: '10px' }}>
                      Product Benefits:
                    </p>
                    <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>

                      <ArrowRightIcon /> Choose from multiple credit cards to suit your lifestyle
                    </p>
                    <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>

                      <ArrowRightIcon /> Complimentary airport lounge access (on some variants)
                    </p>
                    <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>

                      <ArrowRightIcon /> Exciting rewards and cashback benefits
                    </p>
                    <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>

                      <ArrowRightIcon /> 1% Fuel Surcharge Waiver
                    </p>
                    <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>

                      <ArrowRightIcon /> Flipkart, Amazon, Zomato, PVR vouchers
                    </p>

                  </div>
                )}
              </CardContent>

            </Card>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
            <Card sx={{ maxWidth: 345 }} className="mt-3 mx-auto" style={{
              borderRadius: "20px",
              background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
              backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
              border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
              color: "white"
            }}>

              <CardContent>
                <div className="text-center">
                  <img src={Shoppers} className="img-fluid" style={{ height: "150px" }} title="Axis Credit Card" />
                </div>
                <p gutterBottom className="pt-3" component="div" style={{fontSize:"15px"}}>
                  HDFC Shoppers Stop Credit Card
                </p>

                <div className="d-flex flex-md-row flex-column justify-content-around">
                  <Button variant="outlined" color="inherit" onClick={hdfcstoppersBenefits} sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>
                    {hdfcstoppersbenefits ? "Hide Benefits" : "Show Benefits"}
                  </Button>
                  <a href="https://indiasales.club/TBKH0476/HA2N2699/HC" className="btn-container" style={{ textDecoration: "none" }} target="_blank"><Button variant="contained" className="btn-responsive" sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>Apply Now</Button></a>
                </div>
                {hdfcstoppersbenefits && (
                  <div>
                    <p style={{ fontWeight: 'Bold', marginTop: '10px' }}>
                      Product Benefits:
                    </p>
                    <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>

                      <ArrowRightIcon /> ₹500 Shoppers Stop voucher on spend of ₹3,000
                    </p>
                    <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>

                      <ArrowRightIcon /> Complimentary Shoppers Stop membership
                    </p>
                    <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>

                      <ArrowRightIcon /> UPI - Rupay Credit Card
                    </p>
                    <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>

                      <ArrowRightIcon /> 1% fuel surcharge waiver
                    </p>
                    <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>

                      <ArrowRightIcon /> Earn 6 First Citizen Points for every ₹150 spent
                    </p>

                  </div>
                )}
              </CardContent>

            </Card>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
            <Card sx={{ maxWidth: 345 }} className="mt-3 mx-auto" style={{
              borderRadius: "20px",
              background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
              backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
              border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
              color: "white"
            }}>
              {/* <CardMedia
                sx={{ height: 180 }}
                image="https://www.axisbank.com/images/default-source/revamp_new/cards/cc-new/atlas-credit-card-facia.jpg"
                title="Axis credit card"
              /> */}
              <CardContent>
                <div className="text-center">
                  <img src={HDFC} className="img-fluid" style={{ height: "150px" }} title="Axis Credit Card" />
                </div>
                <p gutterBottom className="pt-3" component="div" style={{fontSize:"15px"}}>
                  HDFC Credit Card
                </p>

                <div className="d-flex flex-md-row flex-column justify-content-around">
                  <Button variant="outlined" color="inherit" onClick={HDFCcreditbenefits} sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>
                    {hdfccreditbenefits ? "Hide Benefits" : "Show Benefits"}
                  </Button>
                  <a href="https://indiasales.club/TBKH0476/HA2N2699/HD" style={{ textDecoration: "none" }} className="btn-container" target="_blank"><Button variant="contained" className="btn-responsive" sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>Apply Now</Button></a>
                </div>
                {hdfccreditbenefits && (
                  <div>
                    <p style={{ fontWeight: 'Bold', marginTop: '10px' }}>
                      Product Benefits:
                    </p>
                    <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>

                      <ArrowRightIcon /> Exclusive reward points and cashbacks on online spends
                    </p>
                    <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>

                      <ArrowRightIcon /> Welcome benefits
                    </p>
                    <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>

                      <ArrowRightIcon /> Vouchers on monthly spends
                    </p>
                    <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>

                      <ArrowRightIcon /> Complimentary Airport Lounge access
                    </p>
                    <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>

                      <ArrowRightIcon /> Fuel surcharge waiver
                    </p>
                  </div>
                )}
              </CardContent>

            </Card>
          </div>
        </div>
        <div className="col-12 p-4 text-center">
          <Button variant="text" sx={{ fontFamily: "Satoshi", textTransform: "none", fontSize: "25px", color: "white" }} onClick={Remainingcardstoggle}>{cardstoggle ? "Explore Less" : "Explore More"} <KeyboardArrowDownIcon /></Button>
        </div>
        {cardstoggle &&
          (
            <>
              <div className="row p-5">
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
                  <Card sx={{ maxWidth: 345 }} className="mt-3 mx-auto" style={{
                    borderRadius: "20px",
                    background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                    backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                    border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                    color: "white"
                  }}>
                    {/* <CardMedia
                sx={{ height: 180 }}
                image="https://www.axisbank.com/images/default-source/revamp_new/cards/cc-new/atlas-credit-card-facia.jpg"
                title="Axis credit card"
              /> */}
                    <CardContent>
                      <div className="text-center">
                        <img src={Icici} className="img-fluid" style={{ height: "150px" }} title="Axis Credit Card" />
                      </div>
                      <p gutterBottom className="pt-3" component="div" style={{fontSize:"15px"}}>
                        ICICI Credit Card
                      </p>

                      <div className="d-flex flex-md-row flex-column justify-content-around">
                        <Button variant="outlined" color="inherit" onClick={IcicicardBenefits} sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>
                          {icicicreditbenefits ? "Hide Benefits" : "Show Benefits"}
                        </Button>
                        <a href="https://indiasales.club/TBKH0476/HA2N2699/IK" className="btn-container" style={{ textDecoration: "none" }} target="_blank"><Button variant="contained" className="btn-responsive" sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>Apply Now</Button></a>
                      </div>
                      {icicicreditbenefits && (
                        <div>
                          <p style={{ fontWeight: 'Bold', marginTop: '10px' }}>
                            Product Benefits:
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/not-allowed-document-line-icon-no-file-sign-vector-office-note-symbol-quality-design-element-editable-stroke-linear-style-wrong-179006422.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Enjoy discounts on shopping, dining, movies, and more
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1146102903/vector/cinema-tickets-icon-signs-and-symbols-can-be-used-for-web-logo-mobile-app-ui-ux.jpg?s=612x612&w=0&k=20&c=Zu7YLfsqjvlASa5VPGKLswpfLCNaD9kHjZkr5kn30ZA="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Enjoy cash rewards and ICICI bank rewards offer
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://t4.ftcdn.net/jpg/01/10/42/49/360_F_110424988_1daItp4q2t4BDi7RBMrMjGrAMctcctFT.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Get complimentary access to airport lounges
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/quick-approval-icon-mark-filled-style-quick-approval-icon-vector-symbol-sign-set-collection-black-white-outline-330062871.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Discounts on fuel purchases and waiver of fuel surcharge
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1384176492/vector/waiting-room-icon-isolated-flat-design-vector-illustration.jpg?s=612x612&w=0&k=20&c=BYUgeOHRNaM-ZyWT7qsHq7AJYs0bBX5nKL1T8D_KDEU="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Discounts on fuel purchases and waiver of fuel surcharge
                          </p>

                        </div>
                      )}
                    </CardContent>

                  </Card>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
                  <Card sx={{ maxWidth: 345 }} className="mt-3 mx-auto" style={{
                    borderRadius: "20px",
                    background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                    backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                    border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                    color: "white"
                  }}>
                    {/* <CardMedia
                sx={{ height: 180 }}
                image="https://www.axisbank.com/images/default-source/revamp_new/cards/cc-new/atlas-credit-card-facia.jpg"
                title="Axis credit card"
              /> */}
                    <CardContent>
                      <div className="text-center">
                        <img src={Idfcfirst} className="img-fluid" style={{ height: "150px" }} title="Axis Credit Card" />
                      </div>
                      <p gutterBottom className="pt-3" component="div" style={{fontSize:"15px"}}>
                        IDFC FIRST Bank Credit Card
                      </p>

                      <div className="d-flex flex-md-row flex-column justify-content-around">
                        <Button variant="outlined" color="inherit" onClick={Idfcbankcardbenefits} sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>
                          {idfcbankcreditbenefits ? "Hide Benefits" : "Show Benefits"}
                        </Button>
                        <a href="https://indiasales.club/TBKH0476/HA2N2699/IF" className="btn-container" style={{ textDecoration: "none" }} target="_blank"><Button variant="contained" className="btn-responsive" sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>Apply Now</Button></a>
                      </div>
                      {idfcbankcreditbenefits && (
                        <div>
                          <p style={{ fontWeight: 'Bold', marginTop: '10px' }}>
                            Product Benefits:
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/not-allowed-document-line-icon-no-file-sign-vector-office-note-symbol-quality-design-element-editable-stroke-linear-style-wrong-179006422.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Lifetime Free Credit Card
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1146102903/vector/cinema-tickets-icon-signs-and-symbols-can-be-used-for-web-logo-mobile-app-ui-ux.jpg?s=612x612&w=0&k=20&c=Zu7YLfsqjvlASa5VPGKLswpfLCNaD9kHjZkr5kn30ZA="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon />  Welcome voucher worth ₹500 on spending ₹5000 within first 30 days
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://t4.ftcdn.net/jpg/01/10/42/49/360_F_110424988_1daItp4q2t4BDi7RBMrMjGrAMctcctFT.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Reward Points<br /> - 3X Rewards on Online and Offline spends<br /> - 10X Rewards on monthly spends above ₹20,000<br /> - 1X Rewards on insurance and utility transactions <br />- No Expiry
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/quick-approval-icon-mark-filled-style-quick-approval-icon-vector-symbol-sign-set-collection-black-white-outline-330062871.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Additional benefits <br /> - 25% Off at movie tickets once a month (via PayTM) <br />- Free road side assistance worth ₹1499 <br />- Up to 20% discount across 1500+ restaurants and merchants
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1384176492/vector/waiting-room-icon-isolated-flat-design-vector-illustration.jpg?s=612x612&w=0&k=20&c=BYUgeOHRNaM-ZyWT7qsHq7AJYs0bBX5nKL1T8D_KDEU="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Insurance protection: Personal Accident Cover, Lost Card Liability Cover
                          </p>
                        </div>
                      )}
                    </CardContent>

                  </Card>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
                  <Card sx={{ maxWidth: 345 }} className="mt-3 mx-auto" style={{
                    borderRadius: "20px",
                    background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                    backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                    border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                    color: "white"
                  }}>
                    {/* <CardMedia
                sx={{ height: 180 }}
                image="https://www.axisbank.com/images/default-source/revamp_new/cards/cc-new/atlas-credit-card-facia.jpg"
                title="Axis credit card"
              /> */}
                    <CardContent>
                      <div className="text-center">
                        <img src={Swyp} className="img-fluid" style={{ height: "150px" }} title="Axis Credit Card" />
                      </div>
                      <p gutterBottom className="pt-3" component="div" style={{fontSize:"15px"}}>
                        IDFC FIRST SWYP Credit Card
                      </p>

                      <div className="d-flex flex-md-row flex-column justify-content-around">
                        <Button variant="outlined" color="inherit" onClick={Idfcfirstswypcardbenefits} sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>
                          {idfcfirstswypbenefits ? "Hide Benefits" : "Show Benefits"}
                        </Button>
                        <a href="https://indiasales.club/TBKH0476/HA2N2699/PY" className="btn-container" style={{ textDecoration: "none" }} target="_blank"><Button variant="contained" className="btn-responsive" sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>Apply Now</Button></a>
                      </div>
                      {idfcfirstswypbenefits && (
                        <div style={{ color: "text.secondary", fontSize: "12px" }}>
                          <p style={{ fontWeight: 'Bold', marginTop: '10px' }}>
                            Product Benefits:
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/not-allowed-document-line-icon-no-file-sign-vector-office-note-symbol-quality-design-element-editable-stroke-linear-style-wrong-179006422.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon />  Discount coupon of ₹2,100 from EaseMyTrip
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1146102903/vector/cinema-tickets-icon-signs-and-symbols-can-be-used-for-web-logo-mobile-app-ui-ux.jpg?s=612x612&w=0&k=20&c=Zu7YLfsqjvlASa5VPGKLswpfLCNaD9kHjZkr5kn30ZA="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Up to 20% discount on Domino’s, Zomato, TataCliq and many more
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://t4.ftcdn.net/jpg/01/10/42/49/360_F_110424988_1daItp4q2t4BDi7RBMrMjGrAMctcctFT.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> 2000 RPs on spending ₹5000 within first 30 days
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/quick-approval-icon-mark-filled-style-quick-approval-icon-vector-symbol-sign-set-collection-black-white-outline-330062871.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon />  1000 RPs on 1st EMI Transaction within first 30 days
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1384176492/vector/waiting-room-icon-isolated-flat-design-vector-illustration.jpg?s=612x612&w=0&k=20&c=BYUgeOHRNaM-ZyWT7qsHq7AJYs0bBX5nKL1T8D_KDEU="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> 25% discount on movie tickets on Paytm
                          </p>
                          <p
                            variant="body2"
                            sx={{ color: "text.secondary", fontSize: "12px", paddingTop: "5px" }}
                          >
                            {/* <img
                              src="https://cdn-icons-png.flaticon.com/512/5952/5952839.png"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Complimentary Railway Lounge Access
                          </p>
                        </div>
                      )}
                    </CardContent>

                  </Card>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
                  <Card sx={{ maxWidth: 345 }} className="mt-3 mx-auto" style={{
                    borderRadius: "20px",
                    background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                    backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                    border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                    color: "white"
                  }}>
                    {/* <CardMedia
                sx={{ height: 180 }}
                image="https://www.axisbank.com/images/default-source/revamp_new/cards/cc-new/atlas-credit-card-facia.jpg"
                title="Axis credit card"
              /> */}
                    <CardContent>
                      <div className="text-center">
                        <img src={HPCL} className="img-fluid" style={{ height: "150px" }} title="Axis Credit Card" />
                      </div>
                      <p gutterBottom className="pt-3" component="div" style={{fontSize:"15px"}}>
                        IDFC FIRST HPCL Credit Card
                      </p>

                      <div className="d-flex flex-md-row flex-column justify-content-around">
                        <Button variant="outlined" color="inherit" onClick={Idfcfirsthpclcardbenefits} sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>
                          {idfcfirsthpclbenefits ? "Hide Benefits" : "Show Benefits"}
                        </Button>
                        <a href="https://indiasales.club/TBKH0476/HA2N2699/FL" className="btn-container"  style={{ textDecoration: "none" }} target="_blank"><Button variant="contained" className="btn-responsive" sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>Apply Now</Button></a>
                      </div>
                      {idfcfirsthpclbenefits && (
                        <div>
                          <p style={{ fontWeight: 'Bold', marginTop: '10px' }}>
                            Product Benefits:
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/not-allowed-document-line-icon-no-file-sign-vector-office-note-symbol-quality-design-element-editable-stroke-linear-style-wrong-179006422.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon />  Welcome benefits worth ₹1250 to ₹1500(Based on variant)
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1146102903/vector/cinema-tickets-icon-signs-and-symbols-can-be-used-for-web-logo-mobile-app-ui-ux.jpg?s=612x612&w=0&k=20&c=Zu7YLfsqjvlASa5VPGKLswpfLCNaD9kHjZkr5kn30ZA="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> UPI Rupay Credit Card
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://t4.ftcdn.net/jpg/01/10/42/49/360_F_110424988_1daItp4q2t4BDi7RBMrMjGrAMctcctFT.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Up to 30x rewards on Fuel and FASTag
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/quick-approval-icon-mark-filled-style-quick-approval-icon-vector-symbol-sign-set-collection-black-white-outline-330062871.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> 0 Annual Fee on spending = ₹50K /1.50 Lacs in previous year (Based on variant)*
                          </p>

                        </div>
                      )}
                    </CardContent>

                  </Card>
                </div>
              </div>
              {/* third row */}
              <div className="row ps-5 pe-5 pb-4">
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
                  <Card sx={{ maxWidth: 345 }} className="mt-3 mx-auto" style={{
                    borderRadius: "20px",
                    background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                    backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                    border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                    color: "white"
                  }}>
                    {/* <CardMedia
                sx={{ height: 180 }}
                image="https://www.axisbank.com/images/default-source/revamp_new/cards/cc-new/atlas-credit-card-facia.jpg"
                title="Axis credit card"
              /> */}
                    <CardContent>
                      <div className="text-center">
                        <img src={Induslandplatinum} className="img-fluid" style={{ height: "150px" }} title="Axis Credit Card" />
                      </div>
                      <p gutterBottom className="pt-3" component="div" style={{fontSize:"15px"}}>
                        Indusland Platinum RuPay Credit Card
                      </p>

                      <div className="d-flex flex-md-row flex-column justify-content-around">
                        <Button variant="outlined" color="inherit" onClick={Induslandplatinumcardbenefits} sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>
                          {induslandplatinumbenefits ? "Hide Benefits" : "Show Benefits"}
                        </Button>
                        <a href="https://indiasales.club/TBKH0476/HA2N2699/DI" className="btn-container" style={{ textDecoration: "none" }} target="_blank"><Button variant="contained" className="btn-responsive" sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>Apply Now</Button></a>
                      </div>
                      {induslandplatinumbenefits && (
                        <div>
                          <p style={{ fontWeight: 'Bold', marginTop: '10px' }}>
                            Product Benefits:
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/not-allowed-document-line-icon-no-file-sign-vector-office-note-symbol-quality-design-element-editable-stroke-linear-style-wrong-179006422.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Lifetime Free Credit Card
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1146102903/vector/cinema-tickets-icon-signs-and-symbols-can-be-used-for-web-logo-mobile-app-ui-ux.jpg?s=612x612&w=0&k=20&c=Zu7YLfsqjvlASa5VPGKLswpfLCNaD9kHjZkr5kn30ZA="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon />  UPI payments, now with Credit Card
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://t4.ftcdn.net/jpg/01/10/42/49/360_F_110424988_1daItp4q2t4BDi7RBMrMjGrAMctcctFT.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Earn 2 Reward Points for every ₹ 100 on non-UPI transactions
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/quick-approval-icon-mark-filled-style-quick-approval-icon-vector-symbol-sign-set-collection-black-white-outline-330062871.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Earn 1 Reward Point for every ₹ 100 on UPI transactions
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1384176492/vector/waiting-room-icon-isolated-flat-design-vector-illustration.jpg?s=612x612&w=0&k=20&c=BYUgeOHRNaM-ZyWT7qsHq7AJYs0bBX5nKL1T8D_KDEU="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> 1% Fuel Surcharge Waiver*
                          </p>
                        </div>
                      )}
                    </CardContent>

                  </Card>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
                  <Card sx={{ maxWidth: 345 }} className="mt-3 mx-auto" style={{
                    borderRadius: "20px",
                    background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                    backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                    border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                    color: "white"
                  }}>
                    {/* <CardMedia
                sx={{ height: 180 }}
                image="https://www.axisbank.com/images/default-source/revamp_new/cards/cc-new/atlas-credit-card-facia.jpg"
                title="Axis credit card"
              /> */}
                    <CardContent>
                      <div className="text-center">
                        <img src={Induslandcredit} className="img-fluid" style={{ height: "150px" }} title="Axis Credit Card" />
                      </div>
                      <p gutterBottom className="pt-3" component="div" style={{fontSize:"15px"}}>
                        Indusland Credit Card
                      </p>

                      <div className="d-flex flex-md-row flex-column justify-content-around pt-xl-4">
                        <Button variant="outlined" color="inherit" onClick={InduslandcreditBenefits} sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>
                          {induslandcreditcardbenefits ? "Hide Benefits" : "Show Benefits"}
                        </Button>
                        <a href="https://indiasales.club/TBKH0476/HA2N2699/IC" className="btn-container" style={{ textDecoration: "none" }} target="_blank"><Button variant="contained" className="btn-responsive" sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>Apply Now</Button></a>
                      </div>
                      {induslandcreditcardbenefits && (
                        <div>
                          <p style={{ fontWeight: 'Bold', marginTop: '10px' }}>
                            Product Benefits:
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/not-allowed-document-line-icon-no-file-sign-vector-office-note-symbol-quality-design-element-editable-stroke-linear-style-wrong-179006422.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon />  Earn attractive reward points and cashback.
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1146102903/vector/cinema-tickets-icon-signs-and-symbols-can-be-used-for-web-logo-mobile-app-ui-ux.jpg?s=612x612&w=0&k=20&c=Zu7YLfsqjvlASa5VPGKLswpfLCNaD9kHjZkr5kn30ZA="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon />  Reward points that never expire - redeem anytime you want
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://t4.ftcdn.net/jpg/01/10/42/49/360_F_110424988_1daItp4q2t4BDi7RBMrMjGrAMctcctFT.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon />  Indus Easy Credit Card - lifetime free card
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/quick-approval-icon-mark-filled-style-quick-approval-icon-vector-symbol-sign-set-collection-black-white-outline-330062871.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Enjoy complimentary airport lounge access
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1384176492/vector/waiting-room-icon-isolated-flat-design-vector-illustration.jpg?s=612x612&w=0&k=20&c=BYUgeOHRNaM-ZyWT7qsHq7AJYs0bBX5nKL1T8D_KDEU="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Fuel surcharge on all petrol pumps across India
                          </p>

                        </div>
                      )}
                    </CardContent>

                  </Card>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
                  <Card sx={{ maxWidth: 345 }} className="mt-3 mx-auto" style={{
                    borderRadius: "20px",
                    background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                    backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                    border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                    color: "white"
                  }}>
                    {/* <CardMedia
                sx={{ height: 180 }}
                image="https://www.axisbank.com/images/default-source/revamp_new/cards/cc-new/atlas-credit-card-facia.jpg"
                title="Axis credit card"
              /> */}
                    <CardContent>
                      <div className="text-center">
                        <div className="text-center">
                          <img src={Cashbacksbi} className="img-fluid" style={{ height: "150px" }} title="Axis Credit Card" />
                        </div>
                      </div>
                      <p gutterBottom className="pt-3" component="div" style={{fontSize:"15px"}}>
                        CASHBACK SBI Credit_Card
                      </p>

                      <div className="d-flex flex-md-row flex-column justify-content-around pt-xl-4">
                        <Button variant="outlined" color="inherit" onClick={CashbacksbiBenefits} sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>
                          {cashbacksbibenefits ? "Hide Benefits" : "Show Benefits"}
                        </Button>
                        <a href="https://indiasales.club/TBKH0476/HA2N2699/CZ" className="btn-container" style={{ textDecoration: "none" }} target="_blank"><Button variant="contained" className="btn-responsive" sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>Apply Now</Button></a>
                      </div>
                      {cashbacksbibenefits && (
                        <div>
                          <p style={{ fontWeight: 'Bold', marginTop: '10px' }}>
                            Product Benefits:
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/not-allowed-document-line-icon-no-file-sign-vector-office-note-symbol-quality-design-element-editable-stroke-linear-style-wrong-179006422.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon />  5% Cashback on online spends
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1146102903/vector/cinema-tickets-icon-signs-and-symbols-can-be-used-for-web-logo-mobile-app-ui-ux.jpg?s=612x612&w=0&k=20&c=Zu7YLfsqjvlASa5VPGKLswpfLCNaD9kHjZkr5kn30ZA="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon />  1% Cashback on offline spends
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://t4.ftcdn.net/jpg/01/10/42/49/360_F_110424988_1daItp4q2t4BDi7RBMrMjGrAMctcctFT.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Cashback auto-credit to SBI Card account
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/quick-approval-icon-mark-filled-style-quick-approval-icon-vector-symbol-sign-set-collection-black-white-outline-330062871.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Reversal of Renewal Fee on annual spends of Rs.2 Lakh
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1384176492/vector/waiting-room-icon-isolated-flat-design-vector-illustration.jpg?s=612x612&w=0&k=20&c=BYUgeOHRNaM-ZyWT7qsHq7AJYs0bBX5nKL1T8D_KDEU="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> 1% fuel surcharge waiver
                          </p>
                        </div>
                      )}
                    </CardContent>

                  </Card>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
                  <Card sx={{ maxWidth: 345 }} className="mt-3 mx-auto" style={{
                    borderRadius: "20px",
                    background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                    backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                    border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                    color: "white"
                  }}>
                    {/* <CardMedia
                style={{ height: 180 }}
                image="https://www.axisbank.com/images/default-source/revamp_new/cards/cc-new/atlas-credit-card-facia.jpg"
                title="Axis credit card"
              /> */}
                    <CardContent>
                      <div className="text-center">
                        <img src={Simplyclicksbi} className="img-fluid" style={{ height: "150px" }} title="Axis Credit Card" />
                      </div>
                      <p gutterBottom className="pt-3" component="div" style={{fontSize:"15px"}}>
                        SimplyCLICK SBI Credit_Card
                      </p>

                      <div className="d-flex flex-md-row flex-column justify-content-around pt-xl-4">
                        <Button variant="outlined" color="inherit" onClick={SimplyclicksbiBenefits} sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>
                          {simplyclicksbibenefits ? "Hide Benefits" : "Show Benefits"}
                        </Button>
                        <a href="https://indiasales.club/TBKH0476/HA2N2699/BK" className="btn-container" style={{ textDecoration: "none" }} target="_blank"><Button variant="contained" className="btn-responsive" sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>Apply Now</Button></a>
                      </div>
                      {simplyclicksbibenefits && (
                        <div>
                          <p style={{ fontWeight: 'Bold', marginTop: '10px' }}>
                            Product Benefits:
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/not-allowed-document-line-icon-no-file-sign-vector-office-note-symbol-quality-design-element-editable-stroke-linear-style-wrong-179006422.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> 10X Reward Points* on Exclusive Online Partners
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1146102903/vector/cinema-tickets-icon-signs-and-symbols-can-be-used-for-web-logo-mobile-app-ui-ux.jpg?s=612x612&w=0&k=20&c=Zu7YLfsqjvlASa5VPGKLswpfLCNaD9kHjZkr5kn30ZA="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Get Amazon.in Gift Card worth ₹500
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://t4.ftcdn.net/jpg/01/10/42/49/360_F_110424988_1daItp4q2t4BDi7RBMrMjGrAMctcctFT.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Earn Reward Points on online shopping
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/quick-approval-icon-mark-filled-style-quick-approval-icon-vector-symbol-sign-set-collection-black-white-outline-330062871.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Annual Fee Reversal on spending ₹1 Lakh in a year
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1384176492/vector/waiting-room-icon-isolated-flat-design-vector-illustration.jpg?s=612x612&w=0&k=20&c=BYUgeOHRNaM-ZyWT7qsHq7AJYs0bBX5nKL1T8D_KDEU="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> e-Vouchers worth ₹2,000 on yearly spends of ₹1 Lakh and ₹2 Lakh
                          </p>
                          <p
                            variant="body2"
                            style={{ color: "text.secondary", fontSize: "12px", paddingTop: "5px" }}
                          >
                            {/* <img
                              src="https://cdn-icons-png.flaticon.com/512/5952/5952839.png"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> 1% fuel surcharge waiver
                          </p>
                        </div>
                      )}
                    </CardContent>

                  </Card>
                </div>
              </div>

              {/* third row */}

              {/* fourth row */}
              <div className="row ps-5 pe-5 pb-5">
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
                  <Card sx={{ maxWidth: 345 }} className="mt-3 mx-auto" style={{
                    borderRadius: "20px",
                    background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                    backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                    border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                    color: "white"
                  }}>
                    {/* <CardMedia
                sx={{ height: 180 }}
                image="https://www.axisbank.com/images/default-source/revamp_new/cards/cc-new/atlas-credit-card-facia.jpg"
                title="Axis credit card"
              /> */}
                    <CardContent>
                      <div className="text-center">
                        <img src={Irctcsbi} className="img-fluid" style={{ height: "150px" }} title="Axis Credit Card" />
                      </div>
                      <p gutterBottom className="pt-3" component="div" style={{fontSize:"15px"}}>
                        IRCTC SBI Platinum Credit_Card
                      </p>

                      <div className="d-flex flex-md-row flex-column justify-content-around">
                        <Button variant="outlined" color="inherit" onClick={Irctcplatinum} sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>
                          {irctcsbiplatinumbenefits ? "Hide Benefits" : "Show Benefits"}
                        </Button>
                        <a href="https://indiasales.club/TBKH0476/HA2N2699/BZ" className="btn-container" style={{ textDecoration: "none" }} target="_blank"> <Button variant="contained" className="btn-responsive" sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>Apply Now</Button></a>
                      </div>
                      {irctcsbiplatinumbenefits && (
                        <div style={{ color: "text.secondary", fontSize: "12px" }}>
                          <p style={{ fontWeight: 'Bold', marginTop: '10px' }}>
                            Product Benefits:
                          </p>
                          <p variant="body2" style={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/not-allowed-document-line-icon-no-file-sign-vector-office-note-symbol-quality-design-element-editable-stroke-linear-style-wrong-179006422.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon />  Earn up to 10% Value Back on Rail ticket purchases
                          </p>
                          <p variant="body2" sx={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1146102903/vector/cinema-tickets-icon-signs-and-symbols-can-be-used-for-web-logo-mobile-app-ui-ux.jpg?s=612x612&w=0&k=20&c=Zu7YLfsqjvlASa5VPGKLswpfLCNaD9kHjZkr5kn30ZA="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> 350 bonus Reward Points on activation
                          </p>
                          <p variant="body2" sx={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://t4.ftcdn.net/jpg/01/10/42/49/360_F_110424988_1daItp4q2t4BDi7RBMrMjGrAMctcctFT.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Skip Transaction Charges on bookings at irctc.co.in
                          </p>
                          <p variant="body2" sx={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/quick-approval-icon-mark-filled-style-quick-approval-icon-vector-symbol-sign-set-collection-black-white-outline-330062871.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Complimentary railway lounge access
                          </p>
                          <p variant="body2" sx={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1384176492/vector/waiting-room-icon-isolated-flat-design-vector-illustration.jpg?s=612x612&w=0&k=20&c=BYUgeOHRNaM-ZyWT7qsHq7AJYs0bBX5nKL1T8D_KDEU="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Earn 1 Reward Point per ₹150 spent*
                          </p>
                          <p
                            variant="body2"
                            sx={{ color: "text.secondary", fontSize: "12px", paddingTop: "5px" }}
                          >
                            {/* <img
                              src="https://cdn-icons-png.flaticon.com/512/5952/5952839.png"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> 1% fuel surcharge waiver
                          </p>
                        </div>
                      )}
                    </CardContent>

                  </Card>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
                  <Card sx={{ maxWidth: 345 }} className="mt-3 mx-auto" style={{
                    borderRadius: "20px",
                    background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                    backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                    border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                    color: "white"
                  }}>
                    {/* <CardMedia
                sx={{ height: 180 }}
                image="https://www.axisbank.com/images/default-source/revamp_new/cards/cc-new/atlas-credit-card-facia.jpg"
                title="Axis credit card"
              /> */}
                    <CardContent>
                      <div className="text-center">
                        <img src={Simplysavesbi} className="img-fluid" style={{ height: "150px" }} title="Axis Credit Card" />
                      </div>
                      <p gutterBottom className="pt-3" component="div" style={{fontSize:"15px"}}>
                        SimplySAVE SBI Credit_Card
                      </p>

                      <div className="d-flex flex-md-row flex-column justify-content-around">
                        <Button variant="outlined" color="inherit" onClick={SimplysaveSbi} sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>
                          {simplysavesbibenefits ? "Hide Benefits" : "Show Benefits"}
                        </Button>
                        <a href="https://indiasales.club/TBKH0476/HA2N2699/VS"  className="btn-container" style={{ textDecoration: "none" }} target="_blank"><Button variant="contained" className="btn-responsive" sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>Apply Now</Button></a>
                      </div>
                      {simplysavesbibenefits && (
                        <div style={{ color: "text.secondary", fontSize: "12px" }}>
                          <p style={{ fontWeight: 'Bold', marginTop: '10px' }}>
                            Product Benefits:
                          </p>
                          <p variant="body2" sx={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/not-allowed-document-line-icon-no-file-sign-vector-office-note-symbol-quality-design-element-editable-stroke-linear-style-wrong-179006422.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon />  10X Rewards on Movies, Dining & Grocery Spends!
                          </p>
                          <p variant="body2" sx={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1146102903/vector/cinema-tickets-icon-signs-and-symbols-can-be-used-for-web-logo-mobile-app-ui-ux.jpg?s=612x612&w=0&k=20&c=Zu7YLfsqjvlASa5VPGKLswpfLCNaD9kHjZkr5kn30ZA="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> On Other Spends, Earn 1 Reward Point per ₹150 Spent
                          </p>
                          <p variant="body2" sx={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://t4.ftcdn.net/jpg/01/10/42/49/360_F_110424988_1daItp4q2t4BDi7RBMrMjGrAMctcctFT.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> 2000 Reward Points on Spending ₹2000 in First 60 Days
                          </p>
                          <p variant="body2" sx={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/quick-approval-icon-mark-filled-style-quick-approval-icon-vector-symbol-sign-set-collection-black-white-outline-330062871.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Annual Fee Reversal on Spending ₹1 Lakh in a Year
                          </p>
                          <p variant="body2" sx={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1384176492/vector/waiting-room-icon-isolated-flat-design-vector-illustration.jpg?s=612x612&w=0&k=20&c=BYUgeOHRNaM-ZyWT7qsHq7AJYs0bBX5nKL1T8D_KDEU="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> 1% Fuel Surcharge Waiver
                          </p>
                        </div>
                      )}
                    </CardContent>

                  </Card>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
                  <Card sx={{ maxWidth: 345 }} className="mt-3 mx-auto" style={{
                    borderRadius: "20px",
                    background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                    backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                    border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                    color: "white"
                  }}>
                    {/* <CardMedia
                sx={{ height: 180 }}
                image="https://www.axisbank.com/images/default-source/revamp_new/cards/cc-new/atlas-credit-card-facia.jpg"
                title="Axis credit card"
              /> */}
                    <CardContent>
                      <div className="text-center">
                        <img src={Swiggy} className="img-fluid" style={{ height: "150px" }} title="Axis Credit Card" />
                      </div>
                      <p gutterBottom className="pt-3" component="div" style={{fontSize:"15px"}}>
                        Swiggy HDFC Bank Credit Card
                      </p>

                      <div className="d-flex flex-md-row flex-column justify-content-around">
                        <Button variant="outlined" color="inherit" onClick={Swiggyhdfc} sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>
                          {swiggyhdfcbenefits ? "Hide Benefits" : "Show Benefits"}
                        </Button>
                        <a href="https://indiasales.club/TBKH0476/HA2N2699/SH" className="btn-container" style={{ textDecoration: "none" }} target="_blank"><Button variant="contained" className="btn-responsive" sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>Apply Now</Button></a>
                      </div>
                      {swiggyhdfcbenefits && (
                        <div style={{ color: "text.secondary", fontSize: "12px" }}>
                          <p style={{ fontWeight: 'Bold', marginTop: '10px' }}>
                            Product Benefits:
                          </p>
                          <p variant="body2" sx={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/not-allowed-document-line-icon-no-file-sign-vector-office-note-symbol-quality-design-element-editable-stroke-linear-style-wrong-179006422.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Lifetime Free for limited time
                          </p>
                          <p variant="body2" sx={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1146102903/vector/cinema-tickets-icon-signs-and-symbols-can-be-used-for-web-logo-mobile-app-ui-ux.jpg?s=612x612&w=0&k=20&c=Zu7YLfsqjvlASa5VPGKLswpfLCNaD9kHjZkr5kn30ZA="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> 10% Cashback on Swiggy Spends*
                          </p>
                          <p variant="body2" sx={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://t4.ftcdn.net/jpg/01/10/42/49/360_F_110424988_1daItp4q2t4BDi7RBMrMjGrAMctcctFT.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> 5% Cashback on Online spends*
                          </p>
                          <p variant="body2" sx={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/quick-approval-icon-mark-filled-style-quick-approval-icon-vector-symbol-sign-set-collection-black-white-outline-330062871.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon />  1% Cahback on all other spends
                          </p>
                          <p variant="body2" sx={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1384176492/vector/waiting-room-icon-isolated-flat-design-vector-illustration.jpg?s=612x612&w=0&k=20&c=BYUgeOHRNaM-ZyWT7qsHq7AJYs0bBX5nKL1T8D_KDEU="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Free 3 month Swiggy One membership worth ₹1199*
                          </p>
                        </div>
                      )}
                    </CardContent>

                  </Card>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
                  <Card sx={{ maxWidth: 345 }} className="mt-3 mx-auto" style={{
                    borderRadius: "20px",
                    background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                    backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                    border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                    color: "white"
                  }}>
                    {/* <CardMedia
                sx={{ height: 180 }}
                image="https://www.axisbank.com/images/default-source/revamp_new/cards/cc-new/atlas-credit-card-facia.jpg"
                title="Axis credit card"
                style={{ height: "250px",width:"auto" }}
              /> */}
                    <CardContent>
                      <div className="text-center">
                        <img src={Tata} className="img-fluid" style={{ height: "150px" }} title="Axis Credit Card" />
                      </div>
                      <p gutterBottom className="pt-3" component="div" style={{fontSize:"15px"}}>
                        Tata Neu HDFC Bank Credit Card
                      </p>

                      <div className="d-flex flex-md-row flex-column justify-content-around">
                        <Button variant="outlined" color="inherit" onClick={Tataneuhdfc} sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>
                          {tataneuhdfcbenefits ? "Hide Benefits" : "Show Benefits"}
                        </Button>
                        <a href="https://indiasales.club/TBKH0476/HA2N2699/TN" style={{ textDecoration: "none"}} className="btn-container" target="_blank" type="button"><Button variant="contained" className="btn-responsive" sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>Apply Now</Button></a>
                      </div>
                      {tataneuhdfcbenefits && (
                        <div style={{ color: "text.secondary", fontSize: "12px" }}>
                          <p style={{ fontWeight: 'Bold', marginTop: '10px' }}>
                            Product Benefits:
                          </p>
                          <p variant="body2" sx={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/not-allowed-document-line-icon-no-file-sign-vector-office-note-symbol-quality-design-element-editable-stroke-linear-style-wrong-179006422.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon />  Extraordinary Rewards. Ready for You.
                          </p>
                          <p variant="body2" sx={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1146102903/vector/cinema-tickets-icon-signs-and-symbols-can-be-used-for-web-logo-mobile-app-ui-ux.jpg?s=612x612&w=0&k=20&c=Zu7YLfsqjvlASa5VPGKLswpfLCNaD9kHjZkr5kn30ZA="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Get back 1st year membership fee as NeuCoins on doing a transaction within 90 days
                          </p>
                          <p variant="body2" sx={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://t4.ftcdn.net/jpg/01/10/42/49/360_F_110424988_1daItp4q2t4BDi7RBMrMjGrAMctcctFT.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Up to 10% cashback as NeuCoins on every TATA Neu App transaction.
                            1 NeuCoin = ₹1
                          </p>
                          <p variant="body2" sx={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://thumbs.dreamstime.com/b/quick-approval-icon-mark-filled-style-quick-approval-icon-vector-symbol-sign-set-collection-black-white-outline-330062871.jpg"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> Up to 8 Complimentary Domestic and 4 International Lounge Access
                          </p>
                          <p variant="body2" sx={{ color: "text.secondary", fontSize: "12px" }}>
                            {/* <img
                              src="https://media.istockphoto.com/id/1384176492/vector/waiting-room-icon-isolated-flat-design-vector-illustration.jpg?s=612x612&w=0&k=20&c=BYUgeOHRNaM-ZyWT7qsHq7AJYs0bBX5nKL1T8D_KDEU="
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon /> 1% Fuel surcharge waiver
                          </p>
                          <p
                            variant="body2"
                            sx={{ color: "text.secondary", fontSize: "12px", paddingTop: "5px" }}
                          >
                            {/* <img
                              src="https://cdn-icons-png.flaticon.com/512/5952/5952839.png"
                              className="img-fluid"
                              style={{ width: "35px", height: "35px" }}
                              alt="icon"
                            /> */}
                            <ArrowRightIcon />  Renewal fee waived on annual spends of ₹1 Lakh or ₹3 Lakh (depends on variant)
                          </p>
                        </div>
                      )}
                    </CardContent>

                  </Card>
                </div>
              </div>
              {/* fourth row */}
            </>
          )
        }

      </div>
      {/* Card display */}

      {/* Footer */}
      <Footer />
      {/* Footer */}
    </>
  )
}

export default App;