import React from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import HDFC from './images/card/hdfc/1.png';
import Shoppers from './images/card/hdfc/2.png';
import Tata from './images/card/hdfc/3.png';
import Swiggy from './images/card/hdfc/swiggy.png';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LaunchIcon from '@mui/icons-material/Launch';
import bg from './images/bg.png';


function Hdfcbank() {
  return (
    <>
      <div className="container-fluid" style={{ fontFamily: "Satoshi", backgroundImage: `url(${bg})` }}>
        <div className="row p-3">
          <div className="col-12 ps-5 pe-5 pt-4 pb-4" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
            <h3 className="text-center" style={{ fontFamily: "Azonix", color: "#0B1449", fontSize: "30.26px" }}>Best HDFC Credit Cards</h3>
            <h4 className="text-center mt-2 fw-bold p-2" style={{ fontSize: "20.34px" }}>List of the Best Credit Cards</h4>
            <p className="mt-3 text-center ps-lg-5 pe-lg-5 ps-sm-2 pe-sm-2" style={{ fontSize: "15.47px" }}>
              With a lot of HDFC Bank Credit Cards present in the Indian market, it might be difficult for individuals to choose a card that fits them the best. To help you out in selecting the right one, we are here with the list of all the best HDFC Bank Credit cards in India so that you can go through all of them, their benefits, fees & charges, and apply for the one that seems to be best suited for you.                    </p>
          </div>
          <div className="col-12 mt-4 p-3" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
                <div className="d-lg-flex justify-content-between">
                  <div className="text-center p-5">
                    <img src={Shoppers} className="img-fluid" style={{ height: "180px" }} title="Axis Credit Card" />
                    <h6 gutterBottom className="pt-3 fw-bold" component="div">
                      HDFC Bank Shoppers Stop Credit Card
                    </h6>
                  </div>
                  <div  className="mt-2" style={{ color: "text.secondary", fontSize: "18px" }}>
                    <p>
                      Product Benefits:
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   ₹500 Shoppers Stop voucher on spend of ₹3,000
                    </p>
                    <p variant="body2" >
                      <ArrowRightIcon />  Complimentary Shoppers Stop membership
                    </p>
                    <p variant="body2" >
                      <ArrowRightIcon />  UPI - Rupay Credit Card
                    </p>
                    <p variant="body2" >
                      <ArrowRightIcon />  Earn 6 First Citizen Points for every ₹150 spent
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon /> 1% fuel surcharge waiver
                    </p>

                  </div>
                  <div>
                  <a href="https://indiasales.club/TBKH0476/HA2N2699/HC" style={{ textDecoration: "none" }} target="_blank"><div className="card ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#1976D3" }}><span>Apply Now <LaunchIcon /></span> </div></a>
                  </div>
                </div>
          </div>

          <div className="col-12 mt-4 p-3" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
                <div className="d-lg-flex justify-content-between">
                  <div className="text-center p-5">
                    <img src={HDFC} style={{ height: "180px" }} className="img-fluid" title="Axis Credit Card" />
                    <h6 gutterBottom className="pt-3 fw-bold" component="div">
                      HDFC Credit Card
                    </h6>
                  </div>
                  <div className="mt-2" style={{ color: "text.secondary", fontSize: "18px" }}>
                    <p>
                      Product Benefits:
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  Exclusive reward points and cashbacks on online spends
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  Welcome benefits
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   Vouchers on monthly spends
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  Complimentary Airport Lounge access
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  Fuel surcharge waiver
                    </p>

                  </div>
                  <div>
                  <a href="https://indiasales.club/TBKH0476/HA2N2699/HD" style={{ textDecoration: "none" }} target="_blank"><div className="card ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#1976D3" }}><span>Apply Now <LaunchIcon /></span> </div></a>
                  </div>
                </div>


          </div>

          <div className="col-12 mt-4 p-3" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
                <div className="d-lg-flex justify-content-between">
                  <div className="text-center p-5">
                    <img src={Swiggy} style={{ height: "180px" }} className="img-fluid" title="Axis Credit Card" />
                    <h6 gutterBottom className="pt-3 fw-bold" component="div">
                      Swiggy HDFC Bank Credit Card
                    </h6>
                  </div>
                  <div className="mt-2" style={{ color: "text.secondary", fontSize: "18px" }}>
                    <p>
                      Product Benefits:
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  Lifetime Free for limited time
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  10% Cashback on Swiggy Spends*
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   5% Cashback on Online spends*
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   1% Cahback on all other spends
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   Free 3 month Swiggy One membership worth ₹1199*
                    </p>

                  </div>
                  <div>
                  <a href="https://indiasales.club/TBKH0476/HA2N2699/SH" style={{ textDecoration: "none" }} target="_blank"><div className="card ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#1976D3" }}><span>Apply Now <LaunchIcon /></span> </div></a>
                  </div>
                </div>

          </div>

          <div className="col-12 mt-4 p-3" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
           
                <div className="d-lg-flex justify-content-between">
                  <div className="text-center p-5">
                    <img src={Tata} style={{ height: "180px" }} className="img-fluid" title="Axis Credit Card" />
                    <h6 gutterBottom className="pt-3 fw-bold" component="div">
                      Tata Neu HDFC Bank Credit Card
                    </h6>
                  </div>
                  <div className="mt-2" style={{ color: "text.secondary", fontSize: "16px" }}>
                    <p>
                      Product Benefits:
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   Extraordinary Rewards. Ready for You.
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  Get back 1st year membership fee as NeuCoins on doing a transaction within 90 days
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  Up to 10% cashback as NeuCoins on every TATA Neu App transaction.
                      1 NeuCoin = ₹1
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   Up to 8 Complimentary Domestic and 4 International Lounge Access
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  1% fuel surcharge waiver
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   Renewal fee waived on annual spends of ₹1 Lakh or ₹3 Lakh (depends on variant)
                    </p>

                  </div>
                  <div>
                  <a href="https://indiasales.club/TBKH0476/HA2N2699/TN" style={{ textDecoration: "none" }} target="_blank"><div className="card ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#1976D3" }}><span>Apply Now <LaunchIcon /></span> </div></a>
                  </div>
                </div>

          </div>
          <div className="col-12 p-5"></div>
        </div>
      </div>
    </>
  )
}

export default Hdfcbank;