import React from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Cashbacksbi from './images/card/sbi/cashback.png';
import Irctcsbi from './images/card/sbi/irctcsbi.png';
import Simplysavesbi from './images/card/sbi/simplysave.png';
import Simplyclicksbi from './images/card/sbi/simplyclick.png';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LaunchIcon from '@mui/icons-material/Launch';
import bg from './images/bg.png';
import cardframe from './images/containerframe.png';


function Sbibank() {
  return (
    <>
      <div className="container-fluid" style={{ fontFamily: "Satoshi", backgroundImage: `url(${bg})` }}>
        <div className="row p-3">
          <div className="col-12 ps-5 pe-5 pt-4 pb-4" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>

            <h3 className=" text-center" style={{ fontFamily: "Azonix", color: "#0B1449", fontSize: "30.26px" }}>Best SBI Credit Cards</h3>
            <p className="text-center mt-2 fw-bold p-2" style={{ fontSize: "20.34px" }}>List of the Best Credit Cards</p>
            <p className="mt-3 text-center ps-lg-5 pe-lg-5 ps-sm-2 pe-sm-2" style={{ fontSize: "15.47px" }}>
              SBI Card issues a large number of credit cards, and therefore you may find it difficult to check the benefits and features of all the cards in one place. To make it easier for you, we have made a list of all the best SBI Credit Cards in India. We have also filtered out some of the Top SBI Credit Cards later in this article. To know more about all the cards offered by SBI Card, keep reading so that you can compare on the basis of your needs and apply online wisely.
            </p>

          </div>
          <div className="col-12 mt-4 p-3" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
            <div className="d-lg-flex justify-content-between">
              <div className="text-center p-5">
                <img src={Cashbacksbi} className="img-fluid" style={{ height: "180px" }} title="Axis Credit Card" />
                <h6 gutterBottom className="pt-3 fw-bold" component="div">
                  CASHBACK SBI Credit_Card
                </h6>
              </div>
              <div className="mt-2" style={{ color: "text.secondary", fontSize: "18px" }}>
                <p>
                  Product Benefits:
                </p>
                <p variant="body2" >
                  <ArrowRightIcon />  5% Cashback on online spends
                </p>
                <p variant="body2">
                  <ArrowRightIcon />  1% Cashback on offline spends
                </p>
                <p variant="body2">
                  <ArrowRightIcon /> Cashback auto-credit to SBI Card account
                </p>
                <p variant="body2">
                  <ArrowRightIcon /> Reversal of Renewal Fee on annual spends of Rs.2 Lakh
                </p>
                <p variant="body2">
                  <ArrowRightIcon /> 1% fuel surcharge waiver
                </p>

              </div>
              <div className="p-3">
                <a href="https://indiasales.club/TBKH0476/HA2N2699/CZ" style={{ textDecoration: "none" }} target="_blank"><div className="card ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#1976D3" }}><span>Apply Now <LaunchIcon /></span> </div></a>
              </div>
            </div>
          </div>

          <div className="col-12 mt-4 p-3" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
            <div className="d-lg-flex justify-content-between">
              <div className="text-center p-5">
                <img src={Simplyclicksbi} className="img-fluid" style={{ height: "180px" }} title="Axis Credit Card" />
                <h6 gutterBottom className="pt-3 fw-bold" component="div">
                  SimplyCLICK SBI Credit_Card
                </h6>
              </div>
              <div className="mt-2" style={{ color: "text.secondary", fontSize: "18px" }}>
                <p>
                  Product Benefits:
                </p>
                <p variant="body2">
                  <ArrowRightIcon />  10X Reward Points* on Exclusive Online Partners
                </p>
                <p variant="body2">
                  <ArrowRightIcon />  Get Amazon.in Gift Card worth ₹500
                </p>
                <p variant="body2" >
                  <ArrowRightIcon />  Earn Reward Points on online shopping
                </p>
                <p variant="body2" >
                  <ArrowRightIcon /> Annual Fee Reversal on spending ₹1 Lakh in a year
                </p>
                <p variant="body2" >
                  <ArrowRightIcon />  e-Vouchers worth ₹2,000 on yearly spends of ₹1 Lakh and ₹2 Lakh
                </p>
                <p variant="body2" >
                  <ArrowRightIcon />  1% fuel surcharge waiver
                </p>

              </div>
              <div className="p-3">
                <a href="https://indiasales.club/TBKH0476/HA2N2699/BK" style={{ textDecoration: "none" }} target="_blank"><div className="card ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#1976D3" }}><span>Apply Now <LaunchIcon /></span> </div></a>
              </div>
            </div>
          </div>

          <div className="col-12 mt-4 p-3" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
            <div className="d-lg-flex justify-content-between">
              <div className="text-center p-5">
                <img src={Irctcsbi} className="img-fluid" style={{ height: "180px" }} title="Axis Credit Card" />
                <h6 gutterBottom className="pt-3 fw-bold" component="div">
                IRCTC SBI Platinum Credit_Card
                </h6>
              </div>
              <div className="mt-4" style={{ color: "text.secondary", fontSize: "18px" }}>
                    <p>
                      Product Benefits:
                    </p>
                    <p variant="body2" >
                      <ArrowRightIcon />  Earn up to 10% Value Back on Rail ticket purchases
                    </p>
                    <p variant="body2" >
                      <ArrowRightIcon />  350 bonus Reward Points on activation
                    </p>
                    <p variant="body2" >
                      <ArrowRightIcon />  Skip Transaction Charges on bookings at irctc.co.in
                    </p>
                    <p variant="body2" >
                      <ArrowRightIcon />  Complimentary railway lounge access
                    </p>
                    <p variant="body2" >
                      <ArrowRightIcon />  Earn 1 Reward Point per ₹150 spent*
                    </p>
                    <p variant="body2" >
                      <ArrowRightIcon />  1% fuel surcharge waiver
                    </p>

                  </div>
              <div className="p-3 ">
              <a href="https://indiasales.club/TBKH0476/HA2N2699/BZ" style={{ textDecoration: "none" }} target="_blank"><div className="card ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#1976D3" }}><span>Apply Now <LaunchIcon /></span> </div></a>
              </div>
            </div>
          </div>

          <div className="col-12 mt-4 p-3" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
            <div className="d-lg-flex justify-content-between">
              <div className="text-center p-5">
                <img src={Simplysavesbi} className="img-fluid" style={{ height: "180px" }} title="Axis Credit Card" />
                <h6 gutterBottom className="pt-3 fw-bold" component="div">
                SimplySAVE SBI Credit_Card
                </h6>
              </div>
              <div className="mt-4" style={{ color: "text.secondary", fontSize: "18px" }}>
                    <h6>
                      Product Benefits:
                    </h6>
                    <p variant="body2" >
                      <ArrowRightIcon />  10X Rewards on Movies, Dining & Grocery Spends!
                    </p>
                    <p variant="body2" >
                      <ArrowRightIcon />  On Other Spends, Earn 1 Reward Point per ₹150 Spent
                    </p>
                    <p variant="body2" >
                      <ArrowRightIcon />  2000 Reward Points on Spending ₹2000 in First 60 Days
                    </p>
                    <p variant="body2" >
                      <ArrowRightIcon />   Annual Fee Reversal on Spending ₹1 Lakh in a Year
                    </p>
                    <p variant="body2" >
                      <ArrowRightIcon />  1% fuel surcharge waiver
                    </p>

                  </div>
              <div className="p-3 ">
              <a href="https://indiasales.club/TBKH0476/HA2N2699/VS" style={{ textDecoration: "none" }} target="_blank"><div className="card ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#1976D3" }}><span>Apply Now <LaunchIcon/></span></div></a>
              </div>
            </div>
          </div>
          <div className="col-12 p-5"></div>
        </div>
      </div>
    </>
  )
}

export default Sbibank;