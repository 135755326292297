import React from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Card, CardContent, FormControl, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';

const Login = () => {

    const[email,setEmail]=React.useState('');
    const[password,setPassword]=React.useState('');

    const navigate=useNavigate();

    const submitlogin=()=>{
        if(email==='info@selectcards.in' && password==='selectcards'){
            alert('Login Successful...');
            navigate('/dashboard');
        }else{
            alert('Invalid Credentials');
        }

    }

    return (
        <>
            <div className="container mx-auto mt-5" style={{backgroundColor:"#ECECEC"}}>
                <div className="row p-5">
                    {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    <img src="https://account.asus.com/img/login_img02.png" alt="Login image" 
                    className="img-fluid" style={{width:"300px",height:"auto"}} />
                    </div> */}
                    <div className="col-12">
                        <div className="card mt-4" >
                            <div className="card-body">
                            <Typography variant="h4" className="text-center mb-3">Login</Typography>
                            <Typography>Email Address:</Typography>
                            <TextField id="standard-basic" className="form-control mb-2" type="email" value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="Enter Your Email Id" variant="outlined" required />
                            <br/>
                            <label>Password:</label>
                            <TextField id="standard-basic" className="form-control" type="password" value={password} onChange={(e)=>setPassword(e.target.value)} placeholder="Enter the Password" variant="outlined" required />
                            <br/>
                            <div className="text-center">
                            <Button onClick={submitlogin} className="mt-4" variant="contained">Submit</Button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}

export default Login;