import React from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import yesbank from './images/card/Yesbank/yesbankdualcards.png';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LaunchIcon from '@mui/icons-material/Launch';
import bg from './images/bg.png';


function Axisbank() {
  return (
    <>
      <div className="container-fluid" style={{ fontFamily: "Satoshi", backgroundImage: `url(${bg})` }}>
        <div className="row p-3">
          <div className="col-12 ps-5 pe-5 pt-4 pb-4" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
            <h3 className=" text-center" style={{ fontFamily: "Azonix", color: "#0B1449", fontSize: "30.26px" }}>Best Axis Bank Credit Cards</h3>
            <h4 className="text-center mt-2 fw-bold p-2" style={{ fontSize: "20.34px" }}>List of the Best Credit Cards</h4>
            <p className="mt-3 text-center ps-lg-5 pe-lg-5 ps-sm-2 pe-sm-2" style={{ fontSize: "15.47px" }}>
            Axis Bank has a number of credit cards in its portfolio, and hence, it becomes quite difficult for individuals to go through all the features and benefits of all the cards in order to choose the best Axis Bank Credit Card. We are here with this article containing all the information about the top Axis Bank Credit Cards so that you can compare them easily on the basis of different factors and select the right one. Some of the most popular Axis Bank credit cards are – Flipkart Axis, Select, Ace, and Horizon. For more information, check out the latest range of credit cards by Axis Bank and apply today!  
            </p>        
            </div>
          <div className="col-12 mt-4 p-3" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
                <div className="d-lg-flex justify-content-between">
                  <div className="text-center p-5">
                    <img src={yesbank} className="img-fluid" style={{ height: "180px",width:"auto" }} title="ICICI Credit Card" />
                    <h6 gutterBottom className="pt-3 fw-bold" component="div">
                    Axis Bank Credit Card
                    </h6>
                  </div>
                  <div className="mt-3" style={{ color: "text.secondary", fontSize: "16px" }}>
                    <p className="fw-bold">
                      Product Benefits:
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   Choose from multiple credit cards to suit your lifestyle
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  Complimentary airport lounge access (on some variants)

                    </p>
                    <p variant="body2">
                      <ArrowRightIcon /> Exciting rewards and cashback benefits
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />    1% Fuel Surcharge Waiver
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   Flipkart, Amazon, Zomato, PVR vouchers
                    </p>
                  </div>
                  <div>
                  <a href="https://indiasales.club/TBKH0476/HA2N2699/ACK" style={{ textDecoration: "none" }} target="_blank"><Card className=" ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#0097FF" }}><span>Apply Now <LaunchIcon /></span> </Card></a>
                  </div>
                </div>
                
          </div>

          <div className="col-12 p-5"></div>
        </div>
      </div>
    </>
  )
}

export default Axisbank;