import React from 'react';
import ReactDOM from 'react-dom/client';
import pic from './images/logo.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Typography } from "@mui/material";
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import Submit from './Submitform';
import './index.css';


const Navbar = () => {

    const location = useLocation();
    const isAdminPage = location.pathname === '/admin';
    // Check if the current path is '/admin'

    // If it's the admin page, do not render the navbar
    if (isAdminPage) return null;

    return (
        <>
            <nav class="navbar navbar-expand-lg" style={{ fontFamily: "Azonix", background: "linear-gradient(to bottom right, #FFFFFF 0%, #CACDD1 100%)" }} >
                <div class="container-fluid">
                    <Link class="navbar-brand" to='/'><img src={pic} alt="Logo" style={{ height: "60px" }} className="img-fluid" /></Link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        {/* <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <Link smooth to='/' style={{textDecoration:"none"}}><p class="nav-link active fw-bold" variant="h6">Home</p></Link>
              </li>
              <li class="nav-item">
                <Link smooth to="/#about" style={{textDecoration:"none"}}><p class="nav-link active fw-bold" variant="h6">About Us</p></Link>
              </li>
              <li class="nav-item">
                <Link smooth to="/#category" style={{textDecoration:"none"}}><p class="nav-link active fw-bold" variant="h6">Card issuers</p></Link>
              </li>
              <li class="nav-item">
                <Link smooth to='/#contact' style={{textDecoration:"none"}}><p class="nav-link active fw-bold" variant="h6">Contact Us</p></Link>
              </li>
            </ul> */}
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0" style={{color:"#0B1449"}}>
                            <li class="nav-item active">
                                <Link smooth to='/' style={{ textDecoration: "none",color:"#0B1449" }}><h6 class="nav-link active" style={{color:"#0B1449"}}>Home</h6></Link>
                            </li>
                            <li className="nav-item dropdown">
                                <h5
                                    className="nav-link dropdown-toggle active"
                                    id="cardIssuersDropdown"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    style={{color:"#0B1449"}}

                                >
                                    Card Issuers
                                </h5>
                                <div
                                    className="dropdown-menu p-4"
                                    aria-labelledby="cardIssuersDropdown"

                                >
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-4 mb-3">
                                                <Link to='/sbi'><Button variant='text' style={{ textTransform: "none", color: "black", fontFamily: "Satoshi" }}><img
                                                    src="https://yt3.googleusercontent.com/-SqWW5wu-_p90A2gCAAD8NtrtHVz43jrq84P4BYHRkNrGbRBNqYwnUY2s4SKPbsuio-Elt8ONg=s900-c-k-c0x00ffffff-no-rj"
                                                    className="img-fluid"
                                                    alt="Sbi logo"
                                                    style={{ height: "35px", objectFit: "contain" }}
                                                /> <span className='ms-1'>SBI Bank</span></Button>
                                                </Link>
                                            </div>
                                            <div className="col-12 col-md-4 mb-3">
                                                <Link to='/hdfc'><Button variant='text' style={{ textTransform: "none", color: "black", fontFamily: "Satoshi" }}><img
                                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS91g86GYPEmouJD3LiPGSYtYYW9XycFIu4pA&s"
                                                    className="img-fluid"
                                                    alt="Bank 2"
                                                    style={{ height: "35px", objectFit: "contain" }}
                                                /> <span className='ms-1'>HDFC Bank</span></Button></Link>
                                            </div>
                                            <div className="col-12 col-md-4 mb-3">
                                                <Link to='/idfc'><Button variant='text' style={{ textTransform: "none", color: "black", fontFamily: "Satoshi" }}><img
                                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8zAbKhIggRs8aVN2TWlP-nF38Jlm78p9KOA&s"
                                                    className="img-fluid"
                                                    alt="Bank 3"
                                                    style={{ height: "35px", objectFit: "contain" }}
                                                /> <span className='ms-1'>IDFC Bank</span></Button></Link>
                                            </div>
                                            <div className="col-12 col-md-4 mb-3">
                                                <Link to='/induslnd'><Button variant='text' style={{ textTransform: "none", color: "black", fontFamily: "Satoshi" }}> <img
                                                    src="https://yt3.googleusercontent.com/ytc/AIdro_lrGegp6ZaJYoTk_fCeOgguHPZtuGQnV7d1nc4j1RqxtuA=s900-c-k-c0x00ffffff-no-rj"
                                                    className="img-fluid"
                                                    alt="Bank 3"
                                                    style={{ height: "35px" }}
                                                />  <span className='ms-1'>Induslnd Bank</span></Button></Link>
                                            </div>
                                            <div className="col-12 col-md-4 mb-3">
                                                <Link to='/AU'><Button variant='text' style={{ textTransform: "none", color: "black", fontFamily: "Satoshi" }}> <img
                                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8WIApRpRkL6hocgZEGDq1M4-XbMRwLm3NVg&s"
                                                    className="img-fluid"
                                                    alt="Bank 3"
                                                    style={{ height: "35px" }}
                                                />  <span className='ms-1'>AU Bank</span></Button></Link>
                                            </div>
                                            <div className="col-12 col-md-4 mb-3">
                                                <Link to='/icici'><Button variant='text' style={{ textTransform: "none", color: "black", fontFamily: "Satoshi" }}> <img
                                                    src="https://i.pinimg.com/736x/ff/d5/31/ffd531a6a78464512a97848e14506738.jpg"
                                                    className="img-fluid"
                                                    alt="Bank 3"
                                                    style={{ height: "35px" }}
                                                />  <span className='ms-1'>ICICI Bank</span></Button></Link>
                                            </div>
                                            <div className="col-12 col-md-4 mb-3">
                                                <Link to='/yesbank'><Button variant='text' style={{ textTransform: "none", color: "black", fontFamily: "Satoshi" }}> <img
                                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Yes_Bank_SVG_Logo.svg/1200px-Yes_Bank_SVG_Logo.svg.png"
                                                    className="img-fluid"
                                                    alt="Yesbank"
                                                    style={{ height: "35px",width:"55px" }}
                                                />  <span className='ms-1'>YES Bank</span></Button></Link>
                                            </div>
                                            <div className="col-12 col-md-4 mb-3">
                                                <Link to='/axis'><Button variant='text' style={{ textTransform: "none", color: "black", fontFamily: "Satoshi" }}> <img
                                                    src="https://companieslogo.com/img/orig/AXISBANK.BO-8f59e95b.png?t=1720244490"
                                                    className="img-fluid"
                                                    alt="Axisbank"
                                                    style={{ height: "35px"}}
                                                />  <span className='ms-1'>Axis Bank</span></Button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item active">
                                <Link smooth to='/prepaid' style={{ textDecoration: "none" }}><h6 class="nav-link active" style={{color:"#0B1449"}}>Prepaid Cards</h6></Link>
                            </li>
                        </ul>

                        <form class="d-flex justify-content-around" role="search">
                            <div>
                            <a href="https://www.facebook.com/profile.php?id=61568955067891" target="_blank"><FacebookIcon sx={{color:"#0B1449"}} /></a>
                            </div>
                            <div>
                            <a href="https://www.instagram.com/selectcards.in/" target='_blank'><InstagramIcon sx={{color:"#0B1449"}} /></a>
                            </div>
                            <div>
                            <a href="https://www.linkedin.com/company/105048799/admin/page-posts/published/" target='_blank'><LinkedInIcon sx={{color:"#0B1449"}} /></a>
                            </div>
                            <div>
                            <a href="https://wa.me/919381850425" className="text-dark" target='_blank'><WhatsAppIcon sx={{color:"#0B1449"}} /></a>
                            </div>
                        </form>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar;