import React from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Icici from './images/card/icici/icici.png';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LaunchIcon from '@mui/icons-material/Launch';
import bg from './images/bg.png';


function Icicibank() {
  return (
    <>
      <div className="container-fluid" style={{ fontFamily: "Satoshi", backgroundImage: `url(${bg})` }}>
        <div className="row p-3">
          <div className="col-12 ps-5 pe-5 pt-4 pb-4" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
            <h3 className=" text-center" style={{ fontFamily: "Azonix", color: "#0B1449", fontSize: "30.26px" }}>Best ICICI Bank Credit Cards</h3>
            <h4 className="text-center mt-2 fw-bold p-2" style={{ fontSize: "20.34px" }}>List of the Best Credit Cards</h4>
            <p className="mt-3 text-center ps-lg-5 pe-lg-5 ps-sm-2 pe-sm-2" style={{ fontSize: "15.47px" }}>
              With a lot of ICICI Bank credit card options available out there, it becomes pretty hard for individuals to go through all the features as well the benefits that these cards offer. Therefore, to help you out with this, we have listed the best ICICI credit cards available in India so that you can go through them all quickly and apply for a card on the basis of their benefits and features that suit you the best.                    </p>
          </div>
          <div className="col-12 mt-4 p-3" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
                <div className="d-lg-flex justify-content-between">
                  <div className="text-center p-5">
                    <img src={Icici} className="img-fluid" style={{ height: "180px" }} title="ICICI Credit Card" />
                    <h6 gutterBottom className="pt-3 fw-bold" component="div">
                      ICICI Credit Card
                    </h6>
                  </div>
                  <div className="mt-2" style={{ color: "text.secondary", fontSize: "16px" }}>
                    <p>
                      Product Benefits:
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   Enjoy discounts on shopping, dining, movies, and more
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  Enjoy cash rewards and ICICI bank rewards offer
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  Get complimentary access to airport lounges
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   Discounts on fuel purchases and waiver of fuel surcharge
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  No joining and annual fee on selected variants
                    </p>

                  </div>
                  <div>
                  <a href="https://indiasales.club/TBKH0476/HA2N2699/IK" style={{ textDecoration: "none" }} target="_blank"><Card className=" ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#0097FF" }}><span>Apply Now <LaunchIcon /></span> </Card></a>
                  </div>
                </div>
          </div>

          <div className="col-12 p-5"></div>
        </div>
      </div>
    </>
  )
}

export default Icicibank;