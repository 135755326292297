import * as React from 'react';
import * as XLSX from 'xlsx';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  CircularProgress,
  Card,
  CardContent,
} from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BuildIcon from '@mui/icons-material/Build';
import StarIcon from '@mui/icons-material/Star';
import ArticleIcon from '@mui/icons-material/Article';
import ContactsIcon from '@mui/icons-material/Contacts';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { red } from '@mui/material/colors';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);




export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState('Dashboard'); // State to manage the displayed content
  const [enquirydata, setEnquirydata] = React.useState([]);
  const [filteredEnquiryData, setFilteredEnquiryData] = React.useState([]);
  const [filteredContactData, setFilteredContactData] = React.useState([]);
  const [contactdata, setContactdata] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [sanckbaropen, setSnackbaropen] = React.useState(false);
  const [snackbarmessage, setSnackbarmessage] = React.useState('');
  const [nameFilter, setNameFilter] = React.useState('');
  const [emailFilter, setEmailFilter] = React.useState('');
  const [dateFilter, setDateFilter] = React.useState('');
  const [contactnameFilter, setContactNameFilter] = React.useState('');
  const [contactemailFilter, setContactEmailFilter] = React.useState('');
  const [contactdateFilter, setContactDateFilter] = React.useState('');

  // snackbar
  const handleClose = () => {

    setSnackbaropen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  // snackbar

// CSV Report
const handleGenerateCSV = () => {
  if (filteredContactData.length === 0) {
    setSnackbarmessage("No data available to export.");
    setSnackbaropen(true);
    return;
  }

  // Prepare CSV data
  const headers = [
    "ID",
    "Name",
    "Email Address",
    "Mobile No",
    "Monthly Income",
    "Employment Status",
    "No. of Existing Cards/Loans",
    "Highest Limit of Existing Card",
    "City",
    "Date & Time",
  ];

  const rows = filteredContactData.map(row => [
    row.ID,
    row.customername,
    row.email,
    row.mobileno,
    row.monthlyincome,
    row.employmentstatus,
    row.existingcards,
    row.highestlimit,
    row.city,
    row.DateTime,
  ]);

  const csvContent = [
    headers.join(","),
    ...rows.map(row => row.map(value => `"${value}"`).join(",")),
  ].join("\n");

  // Create a Blob and download the file
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "ContactData.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


// CSV Report

// Enquiry CSV Report
const handleGenerateEnquiryCSV = () => {
  if (filteredEnquiryData.length === 0) {
    setSnackbarmessage("No data available to export.");
    setSnackbaropen(true);
    return;
  }

  // Prepare data for Excel
  const rows = filteredEnquiryData.map(row => ({
    ID: row.id, // Ensure this property exists in your data
    Name: row.customername, // Ensure this property exists in your data
    EmailAddress: row.email, // Ensure this property exists in your data
    MobileNo: row.mobileno, // Ensure this property exists in your data
    DateTime: row.datetime, // Ensure this property exists in your data
  }));

  // Create a new workbook and add the data
  const worksheet = XLSX.utils.json_to_sheet(rows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Enquiry Data');

  // Export the Excel file
  XLSX.writeFile(workbook, 'EnquiryData.xlsx');
}
// Enquiry CSV Report


  // loader

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Disable loader after 3 seconds
    }, 6000);

    return () => clearTimeout(timer);
  }, [])
  // loader



  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch('https://selectcards.in/dataget.php'); // Replace with your API URL
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setEnquirydata(data.users); // Update state with fetched data
        setFilteredEnquiryData(data.users);
        console.log(data);
      } catch (err) {
        console.log(err.message); // Set error if any
      }
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://selectcards.in/getcontact.php'); // Replace with your API URL
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setContactdata(data.data); // Update state with fetched data
        setFilteredContactData(data.data);
        console.log(data.data.length);
      } catch (err) {
        console.log(err.message); // Set error if any
      }
    };

    fetchData();
  }, []);

  // Enquiry Search

  const handleSearch = () => {
    const filtered = enquirydata.filter((row) => {
      const matchesName = nameFilter
        ? row.customername.toLowerCase().includes(nameFilter.toLowerCase())
        : true;
      const matchesEmail = emailFilter
        ? row.email.toLowerCase().includes(emailFilter.toLowerCase())
        : true;
      const matchesDate = dateFilter
        ? row.datetime.startsWith(dateFilter) // Match date filter
        : true;

      return matchesName && matchesEmail && matchesDate;
    });

    setFilteredEnquiryData(filtered); // Update the filtered data
  };

  const resetFilters = () => {
    setNameFilter('');
    setEmailFilter('');
    setDateFilter('');
    setFilteredEnquiryData(enquirydata); // Reset to full data
  };

  // Enquiry Search

  // Contact Search

  const ContacthandleSearch = () => {
    const filteredData = contactdata.filter((row) => {
      const nameMatches = contactnameFilter
        ? row.customername.toLowerCase().includes(contactnameFilter.toLowerCase())
        : true;
      const emailMatches = contactemailFilter
        ? row.email.toLowerCase().includes(contactemailFilter.toLowerCase())
        : true;
      const dateMatches = contactdateFilter
        ? row.DateTime.startsWith(contactdateFilter) // Match date filter
        : true;

      return nameMatches && emailMatches && dateMatches;
    });
    setFilteredContactData(filteredData);
  };

  const handleReset = () => {
    setContactNameFilter('');
    setContactEmailFilter('');
    setContactDateFilter('');
    setFilteredContactData(contactdata);
  };

  // Contact Search


  // Delete the Enquiry
  const handleDelete = async (userId) => {
    if (!userId) {
      alert("Please enter a user ID.");
      return;
    }

    try {
      setLoading(true);
      const response = await fetch("https://selectcards.in/deleteenquiry.php", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ id: userId }),
      });

      const data = await response.json();

      if (data.status === "success") {
        setSnackbarmessage(`User with ID ${userId} deleted successfully!`);
        setSnackbaropen(true);
        setFilteredEnquiryData((prevData) => prevData.filter((row) => row.id !== userId));
      } else {
        setSnackbarmessage(data.message);
        setSnackbaropen(true);
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("An error occurred while deleting the user.");
    } finally {
      setLoading(false); // Stop loading after the operation
    }
  };

  // Delete the Enquiry


  // Delete the Contact
  const deleteRecord = async (id) => {
    try {
      const response = await fetch('https://selectcards.in/deletecontact.php', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({ ID: id }),
      });

      const result = await response.json();

      if (result.status === 'success') {
        setSnackbarmessage(result.message); // Notify the user
        // Refresh the data or update the state to remove the deleted record
        setSnackbaropen(true);
        setFilteredContactData((prevData) => prevData.filter((row) => row.id !== id));
      } else {
        setSnackbarmessage(result.message); // Handle error
        setSnackbaropen(true);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
      setSnackbarmessage('Something went wrong. Please try again.');
      setSnackbaropen(true);
    }
  };
  // Delete the Contact

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Snackbar
        open={sanckbaropen}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbarmessage}
        action={action}
      />
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" className='bg-secondary p-2' open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Welcome to the Dashboard!
            </Typography>
            <div className="ms-auto">
            <Link to='/'><Button variant='contained' sx={{backgroundColor:"red"}}><LogoutIcon/> Logout</Button></Link>
          </div>
          </Toolbar>
          
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {['Dashboard', 'Services', 'Testimonials', 'Blog'].map((text) => {
              // Define the icon mapping
              const iconMapping = {
                Dashboard: <DashboardIcon />,
                Services: <BuildIcon />,
                Testimonials: <StarIcon />,
                Blog: <ArticleIcon />,
              };

              return (
                <ListItem key={text} disablePadding sx={{ display: 'block', marginTop: "10px" }}>
                  <ListItemButton
                    onClick={() => setContent(text)} // Update content on click
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {iconMapping[text]} {/* Render icon based on text */}
                    </ListItemIcon>
                    <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              );
            })}
            <hr />
            {['Contact', 'Enquiry'].map((text) => {
              // Define the icon mapping
              const iconMapping = {
                Contact: <ContactsIcon />,
                Enquiry: <ContactSupportIcon />,
              };

              return (
                <ListItem key={text} disablePadding sx={{ display: 'block', marginTop: "10px" }}>
                  <ListItemButton
                    onClick={() => setContent(text)} // Update content on click
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {iconMapping[text]} {/* Render icon based on text */}
                    </ListItemIcon>
                    <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {/* Conditionally render content based on the state */}
          {content === 'Dashboard' && <Typography variant="h4">Dashboard Content</Typography>}
          {content === 'Services' && <Typography variant="h4">Services Content</Typography>}
          {content === 'Testimonials' && <Typography variant="h4">Testimonials Content</Typography>}
          {content === 'Blog' && <Typography variant="h4">Blog Content</Typography>}
          {content === 'Contact' &&
            (
              <>
                <div className='container-fluid'>
                  <div className='row'>
                    <div className='col-12 text-center'>
                      <Typography variant='h4'>Contact Data</Typography>
                    </div>
                    <div className='col-12'>
                      <div style={{ marginBottom: '20px', display: "flex", justifyContent: "space-around", marginTop: "20px" }}>
                        <TextField
                          value={contactnameFilter}
                          onChange={(e) => setContactNameFilter(e.target.value)}
                          label="Filter by Name"
                          variant="standard"
                          type='text'
                        />
                        <TextField
                          value={contactemailFilter}
                          onChange={(e) => setContactEmailFilter(e.target.value)}
                          label="Filter by Email"
                          variant="standard"
                          type='email'
                        />
                        <TextField
                          value={contactdateFilter}
                          onChange={(e) => setContactDateFilter(e.target.value)}
                          label=""
                          variant="standard"
                          type='date'
                          style={{ marginTop: "13px" }}
                        />
                        <div>
                          <Button variant="contained" onClick={ContacthandleSearch} >Search</Button>
                          <Button variant="contained" onClick={handleReset} className='ms-2 bg-warning'>Reset</Button>
                          <Button
                            variant="contained"
                            className="ms-2"
                            onClick={handleGenerateCSV}
                            style={{ backgroundColor: "#4CAF50", color: "white" }}
                          >
                            Generate CSV
                          </Button>
                        </div>
                      </div>
                      <TableContainer component={Paper} style={{ backgroundColor: "#ECECEC", maxHeight: "400px" }}>
                        <Table stickyHeader>
                          {/* Table Head */}
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>ID</TableCell>
                              <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Name</TableCell>
                              <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Email Address</TableCell>
                              <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Mobile No</TableCell>
                              <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Monthly Income</TableCell>
                              <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Employment Status</TableCell>
                              <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>No.of Existing Cards/Loans</TableCell>
                              <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Pincode</TableCell>
                              <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>City</TableCell>
                              <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Date&Time</TableCell>
                              <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Action</TableCell>
                            </TableRow>
                          </TableHead>

                          {/* Table Body */}
                          <TableBody>
                            {Array.isArray(filteredContactData) && filteredContactData.length > 0 ? (
                              filteredContactData.map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell sx={{ fontSize: "12px" }}>{row.ID}</TableCell>
                                  <TableCell sx={{ fontSize: "12px" }}>{row.customername}</TableCell>
                                  <TableCell sx={{ fontSize: "12px" }}>{row.email}</TableCell>
                                  <TableCell sx={{ fontSize: "12px" }}>{row.mobileno}</TableCell>
                                  <TableCell sx={{ fontSize: "12px" }}>{row.monthlyincome}</TableCell>
                                  <TableCell sx={{ fontSize: "12px" }}>{row.employmentstatus}</TableCell>
                                  <TableCell sx={{ fontSize: "12px" }}>{row.existingcards}</TableCell>
                                  <TableCell sx={{ fontSize: "12px" }}>{row.highestlimit}</TableCell>
                                  <TableCell sx={{ fontSize: "12px" }}>{row.city}</TableCell>
                                  <TableCell sx={{ fontSize: "12px" }}>{row.DateTime}</TableCell>
                                  <TableCell>
                                    <Button variant='text' onClick={()=>deleteRecord(row.ID)}><DeleteIcon sx={{ color: "red", fontSize: "18px" }} /></Button>
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={11} align="center">
                                  No Data Available
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>

                    </div>
                  </div>
                </div>
              </>
            )}
          {content === 'Enquiry' &&
            (
              <>
                {loading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                    <div className='card'>
                      <div className='card-body'>
                        <CircularProgress />
                        </div>
                    </div>
                  </div>
                ) : (
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-12 text-center'>
                        <Typography variant='h4'>Enquiry Data</Typography>
                      </div>
                      <div className='col-12'>
                        <div style={{ marginBottom: '20px', display: "flex", justifyContent: "space-around", marginTop: "20px" }}>
                          <TextField
                            label="Filter by Name"
                            value={nameFilter}
                            onChange={(e) => setNameFilter(e.target.value)}
                            variant="standard"
                            type='text'
                          />
                          <TextField
                            label="Filter by Email"
                            value={emailFilter}
                            onChange={(e) => setEmailFilter(e.target.value)}
                            variant="standard"
                            type='email'
                          />
                          <TextField
                            label=""
                            value={dateFilter}
                            onChange={(e) => setDateFilter(e.target.value)}
                            variant="standard"
                            type='date'
                            style={{ marginTop: "13px" }}
                          />
                          <div>
                            <Button variant="contained" onClick={handleSearch} >Search</Button>
                            <Button variant="contained" className='ms-2 bg-warning' onClick={resetFilters}>Reset</Button>
                            <Button
                            variant="contained"
                            className="ms-2"
                            onClick={handleGenerateEnquiryCSV}
                            style={{ backgroundColor: "#4CAF50", color: "white" }}
                          >
                            Generate CSV
                          </Button>
                          </div>
                        </div>
                        <TableContainer component={Paper} style={{ backgroundColor: "#ECECEC", maxHeight: "300px" }}>
                          <Table stickyHeader>
                            {/* Table Head */}
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>ID</TableCell>
                                <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Name</TableCell>
                                <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Email Address</TableCell>
                                <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Mobile No</TableCell>
                                <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Date&Time</TableCell>
                                <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            {/* Table Body */}
                            <TableBody>
                              {Array.isArray(filteredEnquiryData) && filteredEnquiryData.length > 0 ? (
                                filteredEnquiryData.map((row, index) => (
                                  <TableRow key={index}>
                                    <TableCell sx={{ fontSize: "12px" }}>{row.id}</TableCell>
                                    <TableCell sx={{ fontSize: "12px" }}>{row.customername}</TableCell>
                                    <TableCell sx={{ fontSize: "12px" }}>{row.email}</TableCell>
                                    <TableCell sx={{ fontSize: "12px" }}>{row.mobileno}</TableCell>
                                    <TableCell sx={{ fontSize: "12px" }}>{row.datetime}</TableCell>
                                    <TableCell>
                                      <Button onClick={() => handleDelete(row.id)}>
                                        <DeleteIcon sx={{ color: "red", fontSize: "18px" }} />
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell colSpan={6} align="center">
                                    No Data Available
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )
          }
        </Box>
      </Box>
    </>
  );
}
