import React from "react";
import { Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import bg from './images/bg.png';


const Submit = () => {
  const [employementstatus, setEmployementstatus] = React.useState("");
  const [Name, setName] = React.useState('');
  const [Email, setEmail] = React.useState('');
  const [Phoneno, setPhoneno] = React.useState('');
  const [Monthlyincome, setMonthlyincome] = React.useState('');
  const [Existingcards, setExistingcards] = React.useState('');
  const [Highestlimit, setHighestlimit] = React.useState('');
  const [city, setCity] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [snackbarmessage, setSnackbarmessage] = React.useState('');


  const handleClose = () => {

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const Submit = async (e) => {
    e.preventDefault();
    const formData = {
      customername: Name,
      email: Email,
      mobileno: Phoneno,
      monthlyincome: Monthlyincome,
      employmentstatus: employementstatus,
      existingcards: Existingcards,
      highestlimit: Highestlimit,
      city: city,
    };

    try {
      const response = await fetch('https://selectcards.in/contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Convert the form data to JSON
      });

      if (response.ok) {
        const result = await response.json();
        setSnackbarmessage('Thankyou Our Representive will contact soon');
        setOpen(true);
        setName('');
        setEmail('');
        setPhoneno('');
        setEmployementstatus('');
        setExistingcards('');
        setHighestlimit('');
        setMonthlyincome('');
        setCity('');
      } else {
        console.error('Error:', response.statusText);
        setSnackbarmessage('Failed to submit form. Please try again.');
        setOpen(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setSnackbarmessage('An error occurred. Please check the console for details.');
      setOpen(true);
    }
  }


  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbarmessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      {/* form to submit */}
      <div className="container-fluid" style={{ fontFamily: "Satoshi", backgroundImage: `url(${bg})` }}>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 p-5" >
            <Card style={{
              borderRadius: "20px",
              background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
              backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
              border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
              color: "white",
              padding: "10px"
            }}>
              <CardContent>
                <h6 variant="h6" className="text-center fw-bold mb-4" style={{ color: "#0B1449" }}><u>How It Works</u></h6>
                <p><span className="fw-bold">1. Tell Us About Yourself:</span> Answer a few quick questions about your spending habits, lifestyle, and financial objectives.
                </p>
                <p className="pt-2"><span className="fw-bold">2. AI-Powered Recommendations:</span> Our cutting-edge AI analyzes your responses to match you with the best card options.
                </p>
                <p className="pt-2"><span className="fw-bold">3. Compare & Choose:</span> Easily compare cards to find the one that fits your needs.
                </p>
                <p className="pt-2"><span className="fw-bold">4. Apply & Start Saving:</span> Apply directly through our platform and enjoy the benefits of your new card!
                </p>
              </CardContent>
            </Card>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 p-5">
            <Card style={{
              borderRadius: "20px",
              background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
              backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
              border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
              color: "white",
              padding: "10px"
            }}>
              <CardContent>
                <h6 variant="h6" className="text-center fw-bold mb-4" style={{color:"#0B1449"}}>
                  <u>To go further Submit your Details</u>
                </h6>
                <form onSubmit={Submit}>
                  {/* Responsive Flex Container */}
                  <div className="d-flex flex-wrap flex-md-nowrap justify-content-center">
                    <div className="w-100 w-md-auto text-center"> {/* Full width on small screens */}
                      <TextField
                      sx={{
                        "& .MuiInputLabel-root": { color: "white" }, // Label color
                        "& .MuiInput-underline:before": { borderBottomColor: "white" }, // Line color before focus
                        "& .MuiInput-underline:hover:before": { borderBottomColor: "white" }, // Line color on hover
                        "& .MuiInput-underline:after": { borderBottomColor: "white" }, // Line color after focus
                        input: { color: "white" }, // Input text color
                      }}
                        id="standard-basic"
                        type="text"
                        value={Name}
                        onChange={(e) => setName(e.target.value)}
                        label="Name"
                        variant="standard"
                        required
                      />
                      <br />
                      <TextField
                      sx={{
                        "& .MuiInputLabel-root": { color: "white" }, // Label color
                        "& .MuiInput-underline:before": { borderBottomColor: "white" }, // Line color before focus
                        "& .MuiInput-underline:hover:before": { borderBottomColor: "white" }, // Line color on hover
                        "& .MuiInput-underline:after": { borderBottomColor: "white" }, // Line color after focus
                        input: { color: "white" }, // Input text color
                      }}
                        id="standard-basic"
                        type="email"
                        value={Email}
                        onChange={(e) => setEmail(e.target.value)}
                        label="Email"
                        variant="standard"
                        required
                      />
                      <br />
                      <TextField
                      sx={{
                        "& .MuiInputLabel-root": { color: "white" }, // Label color
                        "& .MuiInput-underline:before": { borderBottomColor: "white" }, // Line color before focus
                        "& .MuiInput-underline:hover:before": { borderBottomColor: "white" }, // Line color on hover
                        "& .MuiInput-underline:after": { borderBottomColor: "white" }, // Line color after focus
                        input: { color: "white" }, // Input text color
                      }}
                        id="standard-basic"
                        type="number"
                        value={Phoneno}
                        onChange={(e) => setPhoneno(e.target.value)}
                        label="Phone Number"
                        variant="standard"
                        required
                      />
                      <br />
                      <TextField
                      sx={{
                        "& .MuiInputLabel-root": { color: "white" }, // Label color
                        "& .MuiInput-underline:before": { borderBottomColor: "white" }, // Line color before focus
                        "& .MuiInput-underline:hover:before": { borderBottomColor: "white" }, // Line color on hover
                        "& .MuiInput-underline:after": { borderBottomColor: "white" }, // Line color after focus
                        input: { color: "white" }, // Input text color
                      }}
                        id="standard-basic"
                        type="number"
                        value={Monthlyincome}
                        onChange={(e) => setMonthlyincome(e.target.value)}
                        label="Monthly Income"
                        variant="standard"
                        required
                      />
                      <br />
                    </div>
                    <div className="w-100 w-md-auto text-center"> {/* Full width on small screens */}
                      <FormControl variant="standard" sx={{ minWidth: 180}}>
                        <InputLabel id="demo-simple-select-standard-label" sx={{color:"white"}} >Employment Status</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          label="Employment Status"
                          value={employementstatus}
                          onChange={(e) => setEmployementstatus(e.target.value)}
                          sx={{
                            '&:before': {
                                borderBottom: '1px solid white', // Underline color when not focused
                            },
                            '&:after': {
                                borderBottom: '2px solid white', // Underline color when focused
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white', // Border color for outlined variant
                            },
                            input: { color: "white" }, // Input text color
                            '& .MuiSelect-select': {
                                color: 'white', // Selected item color
                            },
                            '& .MuiMenuItem-root': {
                                color: 'white', // Menu item color
                            },
                            '& .MuiMenuItem-root:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.1)', // Hover effect for menu items
                            },
                        }}
                        MenuProps={{
                          PaperProps: {
                              sx: {
                                  backgroundColor: 'black', // Background color of the dropdown
                                  '& .MuiMenuItem-root': {
                                      color: 'white', // Menu item color
                                  },
                              },
                          },
                      }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={"salaried"}>Salaried</MenuItem>
                          <MenuItem value={"Self Employed"}>Self Employed</MenuItem>
                          <MenuItem value={"Business"}>Business</MenuItem>
                        </Select>
                      </FormControl>
                      <br />
                      <FormControl variant="standard" sx={{ minWidth: 180 }}>
                        <InputLabel id="demo-simple-select-standard-label" sx={{color:"white"}} >Existing Cards/Loans</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          label="Existing Cards/Loans"
                          value={Existingcards}
                          onChange={(e) => setExistingcards(e.target.value)}
                          sx={{
                            '&:before': {
                                borderBottom: '1px solid white', // Underline color when not focused
                            },
                            '&:after': {
                                borderBottom: '2px solid white', // Underline color when focused
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white', // Border color for outlined variant
                            },
                            input: { color: "white" }, // Input text color
                            '& .MuiSelect-select': {
                                color: 'white', // Selected item color
                            },
                            '& .MuiMenuItem-root': {
                                color: 'white', // Menu item color
                            },
                            '& .MuiMenuItem-root:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.1)', // Hover effect for menu items
                            },
                        }}
                        MenuProps={{
                          PaperProps: {
                              sx: {
                                  backgroundColor: 'black', // Background color of the dropdown
                                  '& .MuiMenuItem-root': {
                                      color: 'white', // Menu item color
                                  },
                              },
                          },
                      }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={0}>0</MenuItem>
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={6}>6</MenuItem>
                          <MenuItem value={7}>7</MenuItem>
                          <MenuItem value={8}>8</MenuItem>
                          <MenuItem value={9}>9</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                        </Select>
                      </FormControl>
                      {/* <br/>
                      <TextField
                      sx={{
                        "& .MuiInputLabel-root": { color: "white" }, // Label color
                        "& .MuiInput-underline:before": { borderBottomColor: "white" }, // Line color before focus
                        "& .MuiInput-underline:hover:before": { borderBottomColor: "white" }, // Line color on hover
                        "& .MuiInput-underline:after": { borderBottomColor: "white" }, // Line color after focus
                        input: { color: "white" }, // Input text color
                      }}
                        id="standard-basic"
                        type="number"
                        value={Existingcards}
                        onChange={(e) => setExistingcards(e.target.value)}
                        label="Existing Cards/Loans"
                        variant="standard"
                        required
                      /> */}
                      <br />
                      <TextField
                      sx={{
                        "& .MuiInputLabel-root": { color: "white" }, // Label color
                        "& .MuiInput-underline:before": { borderBottomColor: "white" }, // Line color before focus
                        "& .MuiInput-underline:hover:before": { borderBottomColor: "white" }, // Line color on hover
                        "& .MuiInput-underline:after": { borderBottomColor: "white" }, // Line color after focus
                        input: { color: "white" }, // Input text color
                      }}
                        id="standard-basic"
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        label="City"
                        variant="standard"
                        required
                      />
                      <br />
                      <TextField
                      sx={{
                        "& .MuiInputLabel-root": { color: "white" }, // Label color
                        "& .MuiInput-underline:before": { borderBottomColor: "white" }, // Line color before focus
                        "& .MuiInput-underline:hover:before": { borderBottomColor: "white" }, // Line color on hover
                        "& .MuiInput-underline:after": { borderBottomColor: "white" }, // Line color after focus
                        input: { color: "white" }, // Input text color
                      }}
                      inputProps={{
                        pattern: "^[0-9]{6}$", // Pattern for exactly 6 digits
                        maxLength: 6, // Limit input length to 6
                        title: "Please enter a 6-digit pincode", // Tooltip for invalid input
                    }}
                        id="standard-basic"
                        type="number"
                        value={Highestlimit}
                        onChange={(e) => setHighestlimit(e.target.value)}
                        label="Pincode"
                        variant="standard"
                        required
                      />
                    </div>
                    
                  </div>
                  <div className="text-center mt-5">
                    <Button type="submit" variant="contained" style={{ textTransform: "none", fontFamily: "Satoshi" }}>
                      Submit
                    </Button>
                  </div>
                </form>
              </CardContent>
            </Card>
          </div>

        </div>
      </div>
      {/* form to submit */}

    </>
  )
}
export default Submit;