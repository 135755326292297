import React from 'react';
import ReactDOM from 'react-dom/client';
import pic from './images/logo.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Typography } from "@mui/material";
import { BrowserRouter, Routes, Route,useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import Submit from './Submitform';
import './index.css';
import App from './App';
import Admin from './Admin';
import Sbibank from './Sbibank';
import Hdfcbank from './Hdfcbank';
import Idfcbank from './Idfc';
import Induslndbank from './Induslnd';
import Au from './AU';
import Icicibank from './Icici';
import Yesbank from './yesbank';
import Login from './Login';
import Navbar from './Navbar';
import Prepaidcard from './Prepaidcard';
import Axisbank from './Axisbank';

const root = ReactDOM.createRoot(document.getElementById('root'));




root.render(




  <React.StrictMode>
    <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path='/' element={<App />} />
        <Route path='/submit' element={<Submit />} />
        <Route path='/dashboard' element={<Admin />} />
        <Route path='/sbi' element={<Sbibank />} />
        <Route path='/hdfc' element={<Hdfcbank />} />
        <Route path='/idfc' element={<Idfcbank />} />
        <Route path='/induslnd' element={<Induslndbank />} />
        <Route path='/AU' element={<Au />} />
        <Route path='/icici' element={<Icicibank/>}/>
        <Route path='/yesbank' element={<Yesbank/>}/>
        <Route path='/admin' element={<Login/>}/>
        <Route path='/prepaid' element={<Prepaidcard/>}/>
        <Route path='/axis' element={<Axisbank/>}/>
      </Routes>

    </BrowserRouter>
  </React.StrictMode>
);

