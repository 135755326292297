import React from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );

function Cardcategory(){
    return(
        <>
        <div className="container-fluid">
        <div className="row p-5">
          <div className="col-12">
            <h3 className="text-center mb-5">Card Categories</h3>
          </div>
          <div className="col-12 col-sm-12 col-md-0 col-lg-1 col-xl-1 col-xxl-1"></div>
          <div className="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2 col-xxl-2">
            <Card className="mt-3" style={{ backgroundColor: "#ECECEC" }}>
              <CardContent>
                <img src="https://static.thenounproject.com/png/977822-200.png" className="img-fluid" style={{ width: "auto", height: "100px" }} alt="travelcard" />
                <Typography className="fw-bold" style={{ fontSize: "13px" }}>Travel Rewards Cards</Typography>
              </CardContent>       
            </Card>
          </div>
          <div className="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2 col-xxl-2">
            <Card className="mt-3" style={{ backgroundColor: "#ECECEC" }}>
              <CardContent>
                <img src="https://cdn-icons-png.flaticon.com/512/6536/6536309.png" className="img-fluid" style={{ width: "auto", height: "100px" }} alt="travelcard" />
                <Typography className="fw-bold" style={{ fontSize: "14px" }}>Low-Interest Cards</Typography>
              </CardContent>
            </Card>
          </div>
          <div className="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2 col-xxl-2">
            <Card className="mt-3" style={{ backgroundColor: "#ECECEC" }}>
              <CardContent>
                <img src="https://media.istockphoto.com/id/905579300/vector/credit-card-simple-icon-cashback-service.jpg?s=612x612&w=is&k=20&c=DG811fowZlLd5AVcRdJ7JtvdohVxo2ZRDF0WAf9MiX4=" className="img-fluid" style={{ width: "auto", height: "100px" }} alt="travelcard" />
                <Typography className="fw-bold" style={{ fontSize: "14px" }}>Cashback Cards</Typography>
              </CardContent>
            </Card>
          </div>
          <div className="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2 col-xxl-2">
            <Card className="mt-3" style={{ backgroundColor: "#ECECEC" }}>
              <CardContent>
                <img src="https://thumbs.dreamstime.com/z/bank-credit-card-icon-finance-banking-symbol-simple-style-204756933.jpg" className="img-fluid" style={{ width: "auto", height: "100px" }} alt="travelcard" />
                <Typography className="fw-bold" style={{ fontSize: "14px" }}>Credit-Building Cards</Typography>
              </CardContent>
            </Card>
          </div>
          <div className="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2 col-xxl-2">
            <Card className="mt-3" style={{ backgroundColor: "#ECECEC" }}>
              <CardContent>
                <img src="https://www.svgrepo.com/show/308181/accept-credit-cards-credit-card-payment-accept-cards.svg" className="img-fluid" style={{ width: "auto", height: "100px" }} alt="travelcard" />
                <Typography className="fw-bold" style={{ fontSize: "14px" }}>Business Cards</Typography>
              </CardContent>
            </Card>
          </div>
          <div className="col-12 col-sm-12 col-md-0 col-lg-1 col-xl-1 col-xxl-1"></div>
        </div>
      </div>
        </>
    )
}

export default Cardcategory;