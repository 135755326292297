import React from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import yesbank from './images/card/Yesbank/yesbankdualcards.png';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LaunchIcon from '@mui/icons-material/Launch';
import bg from './images/bg.png';


function Yesbank() {
  return (
    <>
      <div className="container-fluid" style={{ fontFamily: "Satoshi", backgroundImage: `url(${bg})` }}>
        <div className="row p-3">
          <div className="col-12 ps-5 pe-5 pt-4 pb-4" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
            <h3 className=" text-center" style={{ fontFamily: "Azonix", color: "#0B1449", fontSize: "30.26px" }}>Best YES Bank Credit Cards</h3>
            <h4 className="text-center mt-2 fw-bold p-2" style={{ fontSize: "20.34px" }}>List of the Best Credit Cards</h4>
            <p className="mt-3 text-center ps-lg-5 pe-lg-5 ps-sm-2 pe-sm-2" style={{ fontSize: "15.47px" }}>
            Have a look at some of the most popular and rewarding Yes Bank Credit Cards. We have compiled all the features, benefits, and fees of these cards so that you can quickly know, compare, and apply for the most suitable Yes Bank Credit Card.                    </p>
          </div>
          <div className="col-12 mt-4 p-3" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
                <div className="d-lg-flex justify-content-between">
                  <div className="col-9 text-center">
                    <img src={yesbank} className="img-fluid" style={{ height: "360px",width:"auto" }} title="ICICI Credit Card" />
                    <h6 gutterBottom className="pt-3 fw-bold" component="div">
                    Yes Bank ANQ Dual Credit Card
                    </h6>
                  </div>
                  <div>
                  <a href="https://indiasales.club/TBKH0476/0NQ28042/IK" style={{ textDecoration: "none" }} target="_blank"><Card className=" ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#0097FF" }}><span>Apply Now <LaunchIcon /></span> </Card></a>
                  </div>
                </div>
                <div className="mt-3" style={{ color: "text.secondary", fontSize: "16px" }}>
                    <p className="fw-bold">
                      Product Benefits:
                    </p>
                    <p>Rupay Credit Card:</p>
                    <p variant="body2">
                      <ArrowRightIcon />   Rupay credit card for UPI, rewards beyond rational
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  With Pi credit card, you can now scan & pay on your all favourite UPI apps like Google Pay, PhonePe with your credit limit

                    </p>
                    <p>Master Credit Card:</p>
                    <p variant="body2">
                      <ArrowRightIcon /> 24RP on every ₹200 spent on your frequent home needs
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   Take your Phi credit card out for all your household expenses and earn 24 reward points on Groceries, supermarkets, department stores and more
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  Get 24 reward points when dining out or ordering in with your family or loved ones
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   Domestic and international lounge access
                    </p>
                  </div>
          </div>

          <div className="col-12 p-5"></div>
        </div>
      </div>
    </>
  )
}

export default Yesbank;