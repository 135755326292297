import React from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Typography } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const Footer=()=>{
    const[Name,setName]=React.useState('');
    const[Email,setEmail]=React.useState('');
    const[MobileNo,setMobileNo]=React.useState('');
    const [open, setOpen] = React.useState(false);
    const[snackbarmessage,setSnackbarmessage]=React.useState('');

    const handleClose = () => {
        setOpen(false);
      };

    const action = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );

    const Submit=async(e)=>{
        e.preventDefault();

    const formData = {
      customername: Name,
      email: Email,
      mobileno: MobileNo,
    };

    try {
      const response = await fetch('https://selectcards.in/database.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Convert the form data to JSON
      });

      if (response.ok) {
        const result = await response.json();
        setSnackbarmessage('Enquiry Submitted Successfully');
        setOpen(true);
        setName('');
        setEmail('');
        setMobileNo('');
      } else {
        console.error('Error:', response.statusText);
        setSnackbarmessage('Failed to submit form. Please try again.');
        setOpen(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setSnackbarmessage('An error occurred. Please check the console for details.');
      setOpen(true);
    }
        
    }
    return(
        <>
         <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbarmessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
        <div className="container-fluid bg-dark" style={{fontFamily:"Satoshi"}}>
            <div className="row p-5">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 pt-4" id="contact">
                      <h4 className=" text-light fw-bold">Get Started Today..</h4>
                      <p className="text-justify text-light mt-3">Choosing the right card doesn’t have to be complicated. Let Select Cards and our AI-driven system help you find the perfect match for your financial goals. Start now to get your personalized recommendations and unlock better rewards, savings, and perks!</p>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 pt-4">
                    <div className="col-12">
                        <h3 className="text-center text-light">Get in Touch with Us!</h3>
                    </div>
                    <div className="text-center">
                        <form onSubmit={Submit}>
                    <TextField id="standard-basic" value={Name} onChange={(e)=>setName(e.target.value)} type="text" label="Name" variant="standard" required
                    sx={{
                        "& .MuiInputLabel-root": { color: "white" }, // Label color
                        "& .MuiInput-underline:before": { borderBottomColor: "white" }, // Line color before focus
                        "& .MuiInput-underline:hover:before": { borderBottomColor: "white" }, // Line color on hover
                        "& .MuiInput-underline:after": { borderBottomColor: "white" }, // Line color after focus
                        input: { color: "white" }, // Input text color
                      }}
                    />
                    <br/>
                    <TextField id="standard-basic" label="Email" value={Email} onChange={(e)=>setEmail(e.target.value)} variant="standard" type="email" required
                     sx={{
                        "& .MuiInputLabel-root": { color: "white" }, // Label color
                        "& .MuiInput-underline:before": { borderBottomColor: "white" }, // Line color before focus
                        "& .MuiInput-underline:hover:before": { borderBottomColor: "white" }, // Line color on hover
                        "& .MuiInput-underline:after": { borderBottomColor: "white" }, // Line color after focus
                        input: { color: "white" }, // Input text color
                      }}
                    />
                    <br/>
                    <TextField id="standard-basic" label="Mobile No" value={MobileNo} onChange={(e)=>setMobileNo(e.target.value)} variant="standard" type="number" required
                     sx={{
                        "& .MuiInputLabel-root": { color: "white" }, // Label color
                        "& .MuiInput-underline:before": { borderBottomColor: "white" }, // Line color before focus
                        "& .MuiInput-underline:hover:before": { borderBottomColor: "white" }, // Line color on hover
                        "& .MuiInput-underline:after": { borderBottomColor: "white" }, // Line color after focus
                        input: { color: "white" }, // Input text color
                      }}
                    />
                    <br/>
                    <Button variant="contained" type="submit" className="mt-3" style={{textTransform:"none",fontFamily:"Satoshi"}}>Submit</Button>
                    </form>
                    </div>
                </div>
            </div>
            
        </div>
        <div className="container-fluid">
        <div className="row">
            <div className="col-12">
                    <p className="text-center p-2">CopyRights Reserved@Collect Intel</p>
                </div>
            </div>
        </div>
        </>
    )
}

export default Footer;